import { TPill } from '../../../ui/PillsList/PillsList';
import { TDropDownListElement } from '../../../ui/DropDownList/DropDownList';
import { TUserSkill } from '../../../../api/getUserSkills/getUserSkills';

function getFilteredSkills(elementsToIgnore:TPill[], suggestions: TUserSkill[]): TDropDownListElement[] {
    const checkIfElementExists = (elementName: string): boolean => {
        return elementsToIgnore.some( elementToIgnore => {
            return elementToIgnore.name === elementName
        });
    };

    return suggestions
        .filter((element: TPill) => !checkIfElementExists(element.name) )
}

export { getFilteredSkills }
