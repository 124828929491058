import { GoldenError } from './GoldenError';
import { TFormError } from '../api/apiUtils/getApiResponse';

class ValidationError extends GoldenError {
    constructor(
        code: string,
        messageCode: string,
        translation: string,
        private formErrors: TFormError[]
    ) {
        super(code, messageCode, translation);
    }

    public get validationErrors(): TFormError[] {
        return this.formErrors;
    }
}

export { ValidationError }
