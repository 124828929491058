import './WhiteBox.scss'
import * as React from 'react';

interface IWhiteBox {
    className?: string;
}

const WhiteBox: React.FC<IWhiteBox> = ({ children, className }) => {
    const classList = [
        'white-box',
        className ? className : ''
    ].join(' ');

    return (
        <div className={classList}>
            {children}
        </div>
    )
};

export { WhiteBox };
