
class Logger {
    private static instance: Logger;

    private constructor() {
        // Prywatny bo singleton
    };

    public static getInstance(): Logger {
        if (!Logger.instance) {
            Logger.instance = new Logger();
        }

        return Logger.instance;
    }

    public logInfo(info: any): void {
        console.log('LOG', info);
    }

    public logError(description: string, error?: any) {
        console.error(`ERROR! ${ description }`, error ? error : '');
    }
}

export { Logger };
