import { Dispatch, SetStateAction, useState } from 'react';
import { TPill } from '../ui/PillsList/PillsList';
import { TErrorMsg } from '../../typings/goldenApp';
import { useTranslation } from 'react-i18next';
import { TDropDownListElement } from '../ui/DropDownList/DropDownList';
import { getUserSkills, TUserSkill } from '../../api/getUserSkills/getUserSkills';
import { getErrorMsgTranslationKey } from '../../utils/getErrorMsgTranslationKey';
import { getFilteredSkills } from '../domain/forms/UserSkillsForm/getFilteredSkills';
import { TSuggestionListUpdateFn } from '../ui/Form/ApiAutocompleter/ApiAutocompleter';
import { updateUserSkills } from '../../api/updateUserSkills/updateUserSkills';
import { Logger } from '../../class/Logger';

interface IUseSkillsList {
    skillsList: TPill[];
    listEditActions: TSkillsListEditActions;
    saveSkills: (afterSave?: TAfterSave) => Promise<void>;
    skillsErrorMsg: TErrorMsg;
    isSaving: boolean;
    getAutocompletedSkills: TSuggestionListUpdateFn;
}

type TSkillsListEditActions = {
    removeItem: (index: number) => void,
    addItem: (skill: TPill) => void
}

type TAfterSave = () => Promise<void>;

const useSkillsList = (skills: TPill[]): IUseSkillsList => {
    const { t } = useTranslation();
    const [ skillsList, updateSkillsList ] = useState(skills);
    const [ errorMsg, updateErrorMsg ] = useState('');
    const [ isSaving, updateIsSaving ] = useState(false);

    const editActions = {
        removeItem: (index: number): void => {
            const newDisplayedSkills = skillsList.filter( (element, i) => {
                return i !== index
            });

            updateErrorMsg('');
            updateSkillsList(newDisplayedSkills);
        },
        addItem: (skill: TPill): void => {
            const newDisplayedSkills: TPill[] = [ ...skillsList, skill ];

            updateErrorMsg('');
            updateSkillsList(newDisplayedSkills);
        }
    };

    const saveSkills = async (afterSave?: TAfterSave): Promise<void> => {
        updateIsSaving(true);

        try {
            const skillsUpdate = await updateUserSkills(skillsList);
            if (skillsUpdate) {
                updateErrorMsg('');
            } else {
                throw skillsUpdate;
            }
        } catch (err) {
            const logger = Logger.getInstance();
            logger.logError(err);
            throw err;
        }

        if (afterSave) {
            await afterSave();
        }

        updateIsSaving(false);
    };

    const getAutocompletedSkills = async (
        query: string,
        updateListFn: Dispatch<SetStateAction<TDropDownListElement[]>>
    ): Promise<void> => {
        const skillSuggestions = await getUserSkills(query);

        if(skillSuggestions['name'] === 'Error') {
            const errorMsgTranslationKey = getErrorMsgTranslationKey(skillSuggestions['message']);
            updateErrorMsg(t(errorMsgTranslationKey))
        } else {
            const filteredSuggestions = getFilteredSkills(skillsList, skillSuggestions as TUserSkill[]);
            updateListFn(filteredSuggestions);
        }
    };

    return {
        skillsList: skillsList,
        listEditActions: editActions,
        saveSkills: saveSkills,
        isSaving: isSaving,
        skillsErrorMsg: { errorMsg, updateErrorMsg },
        getAutocompletedSkills: getAutocompletedSkills
    }
};

export { useSkillsList, TSkillsListEditActions }
