import * as React from 'react';
import './Pill.scss'
import { IconClear } from '../../../../assets/icons/IconClear';

interface IPill {
    fullWidth?: boolean;
    onPillRemove?: (...args : any) => void;
    className?: string;
}

type TPill = {
    name: string,
    id: number | string | null
}

const Pill: React.FC<IPill> = ({ children, fullWidth, onPillRemove, className }) => {
    const classList = [
        'pill',
        onPillRemove ? 'pill--editable' : '',
        fullWidth ? 'pill--full-width' : '',
        className ? className : ''
    ].join(' ');

    return (
        <div className={classList}>
            {children}{onPillRemove && (
                <button type="button" className="pill__icon" onClick={onPillRemove}>
                    <IconClear />
                </button>
            )}
        </div>
    )
};

export { Pill, TPill }
