import { AxiosResponse } from 'axios';
import { getResultError } from './getResultError';
import { GoldenError } from '../../class/GoldenError';

/**
 * Funkcja odpalana po 200'tkach która daje dane albo nasz error
 */
interface IGetApiResponse<T> {
    isSuccess: boolean;
    response: TGoldenAppError | TGoldenAppResult<T>
}

type TGoldenAppInfo = {
    code: string;
    messageCode: string;
}

type TValidationInfo = TGoldenAppInfo & {
    formErrors: TFormError[];
}

type TFormError = {
    fieldName: string;
    fieldErrors: string[];
};

type TGoldenAppError = {
    error: TGoldenAppInfo | TValidationInfo;
}

type TGoldenAppResult<T> = {
    result: {
        data: T;
        message: TGoldenAppInfo;
    };
}

function getApiResponse<T>(response: AxiosResponse): IGetApiResponse<T> {
    const dataAvailable = Boolean(response.data && response.data.result);

    const getResponse = (success: boolean): GoldenError | TGoldenAppResult<T> => {
        if (success) {
            return {
                result: {
                    data: response.data.result.data as T,
                    message: response.data.result.message
                }
            } as TGoldenAppResult<T>;
        }

        return getResultError(response.data);
    };

    return {
        isSuccess: dataAvailable,
        response: getResponse(dataAvailable)
    } as IGetApiResponse<T>;
}

export { getApiResponse, TGoldenAppError, TGoldenAppResult, IGetApiResponse, TFormError, TGoldenAppInfo, TValidationInfo };
