import './GlModal.scss';
import * as React from 'react';

import { WhiteBox } from '../WhiteBox/WhiteBox';
import { MutableRefObject, useEffect, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll, BodyScrollOptions } from 'body-scroll-lock';
import { getClassList } from '../../../utils/getClassList';
import { useState } from 'react';
import { createPortal } from 'react-dom';

interface IGlModal {
    visible: boolean;
    className?: string;
    /**
     * klasy css które nie będą miały zablokowanego scrolla
     */
    innerScrolls?: string[];
}

const GlModal: React.FC<IGlModal> = ({ children, visible, className, innerScrolls }) => {
    const modalRef: MutableRefObject<any | null> = useRef(null);
    const portalContainer = useRef<HTMLElement | null>(null);
    const [ portalContainerReady, setPortalContainerReady ] = useState( false );

    useEffect(() => {
        portalContainer.current = document.createElement('div');
        let portalRoot = document.getElementById('react-portals');
        if (!portalRoot) {
            const newRoot = document.createElement('div');
            newRoot.id = 'react-portals';
            document.body.append(newRoot);
            portalRoot = newRoot;
        }
        portalRoot.appendChild(portalContainer.current);

        setPortalContainerReady(true);

        return function cleanup() {
            if(portalRoot) {
                portalRoot.removeChild(portalContainer.current as HTMLElement);
            }
        };
    }, []);

    useEffect(() => {
        if(portalContainerReady){
            const domModal = modalRef.current;
            if (domModal && visible) {
                const options: BodyScrollOptions = {
                    allowTouchMove: innerScrolls ? (el): any => {
                        return innerScrolls.some((cssClassName: string) => {
                            const domElements = document.getElementsByClassName(cssClassName);
                            return Array.from(domElements).some(domElement => {
                                return domElement.contains(el)
                            })
                        });
                    } : undefined,
                };
                disableBodyScroll(domModal, options);
                domModal.focus()
            }

            if (domModal && !visible) {
                enableBodyScroll(domModal);
            }
        }
    }, [ visible, portalContainerReady ]);

    const classList = getClassList([
        'gl-modal',
        visible ? 'gl-modal--visible' : 'gl-modal--hidden'
    ]);

    return portalContainer.current ? (
        createPortal(
            portalContainerReady ? (
                <div className={classList} ref={modalRef} tabIndex={-1}>
                    <WhiteBox
                        className={`gl-modal__box ${ className ? className : '' }`}
                    >
                        {children}
                    </WhiteBox>
                    <div className="gl-modal__overlay" />
                </div>
            ) : null,
            portalContainer.current as HTMLElement
        )
    ) : null
};

export { GlModal };
