import './CustomItemAdder.scss';
import * as React from 'react';
import { getClassList } from '../../../../../utils/getClassList';

interface ICustomItemAdder {
    itemLabel: string;
    query: string;
    onMouseEnter: () => void;
    onClick: () => void;
    isSelected: boolean;
}

const CustomItemAdder: React.FC<ICustomItemAdder> = ({ itemLabel, query, onMouseEnter, onClick, isSelected }) => {
    const classList = getClassList([
        'autocompleter-dropdown-list-element',
        isSelected ? 'autocompleter-dropdown-list-element--selected' : '',
        'dropdown-list-custom-item'
    ]);

    return (
        <div
            className={classList}
            onMouseEnter={onMouseEnter}
            onClick={onClick}
        >
            <div className="dropdown-list-custom-item__label">
                {itemLabel}
            </div>
            &nbsp;&quot;{query}&quot;
        </div>
    );
};

export { CustomItemAdder, ICustomItemAdder };
