import { TFormError } from '../../../../api/apiUtils/getApiResponse';

type TUpdateFieldFn = (fieldName: string, fieldMsg: string) => void;

function updateFieldErrors(fieldNames: string[], validationErrors: TFormError[], updateFn: TUpdateFieldFn): void {
    for (const validationError of validationErrors) {
        if (fieldNames.includes(validationError.fieldName)) {
            updateFn(validationError.fieldName, validationError.fieldErrors.join(' | '))
        }
    }
}

export { updateFieldErrors, TUpdateFieldFn };
