import * as React from 'react';
import './SvgIcon.scss';

interface ISvgIcon {
    svgXml: string;
    name?: string;
    className?: string;
}

const SvgIcon: React.FC<ISvgIcon> = ({ svgXml, name, className }) => {
    const createMarkup = (): { __html: string } => {
        return { __html: svgXml };
    };

    const classList = [
        'svg-icon',
        `${ name ? `svg-icon--${ name }` : '' }`,
        `${ className ? className : '' }`
    ];

    return (
        <div
            className={classList.join(' ')}
            dangerouslySetInnerHTML={createMarkup()}
        />
    );
};

export { SvgIcon };
