import './DefaultButton.scss';
import * as React from 'react';
import { MouseEvent } from 'react';

import { IconLoading } from '../../../../../assets/icons/IconLoading';
import { getClassList } from '../../../../utils/getClassList';

enum buttonSize {
    L,
    S,
    XS,
    QUERY
}

interface IDefaultButtonProps {
    /**
     * Jeśli ustawione (true), to przycisk będzie w wersji "pustej/białęj"
     *
     */
    hollow?: boolean;
    /**
     * Klasa dodana do elementu
     */
    className?: string
    /**
     * Url przekazywany do buttona, decyduje czy będzie tag <a> czy <button>
     */
    url?: string;
    onClick?: (event?: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
    /**
     * Dostaje type submit jeśli true
     */
    inForm?: boolean
    size?: buttonSize,
    disabled?: boolean,
    loading?: boolean
}

const DefaultButton: React.FC<IDefaultButtonProps> = ({
    children,
    hollow,
    className,
    url,
    onClick,
    inForm,
    size,
    disabled,
    loading
}) => {
    const classList = getClassList([
        'default-button',
        hollow && 'default-button--hollow',
        disabled ? '' : '',
        className && className,
        size === buttonSize.S && 'default-button--small',
        size === buttonSize.XS && 'default-button--xsmall',
        size === buttonSize.QUERY && 'default-button--query'
    ]);

    const childrenClasslist = getClassList([
        'default-button__children-wrap',
        loading ? 'default-button__children-wrap--muted' : ''
    ]);

    if (url) {
        return (
            <a
                className={classList}
                href={url}
                onClick={onClick}
            >
                {children}
            </a>
        );
    }

    return (
        <button
            className={classList}
            type={inForm ? 'submit' : 'button'}
            onClick={onClick}
            disabled={disabled || loading}
        >
            <div className={childrenClasslist}>
                {children}
            </div>
            {loading && (
                <div className="default-button__icon-loading">
                    <IconLoading />
                </div>
            )}
        </button>
    );
};

export { DefaultButton, IDefaultButtonProps, buttonSize };
