import * as React from 'react';
import { SvgIcon } from './SvgIcon';

const IconChevron = () => {
    return (
        <SvgIcon
            name="chevron"
            svgXml={`
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86225 6.19526C5.1226 5.93491 5.54471 5.93491 5.80506 6.19526L8.00033 8.39052L10.1956 6.19526C10.4559 5.93491 10.878 5.93491 11.1384 6.19526C11.3987 6.45561 11.3987 6.87772 11.1384 7.13807L8.00033 10.2761L4.86225 7.13807C4.6019 6.87772 4.6019 6.45561 4.86225 6.19526Z" fill="#7C99A3"/>
                </svg>
            `}
        />
    );
};

export { IconChevron }
