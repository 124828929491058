import './UserLocationForm.scss';
import * as React from 'react';
import { FormEvent, MouseEvent, useState, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { getUserLocationSuggestions, TUserLocation } from '../../../../api/getUserLocationSuggestions/getUserLocationSuggestions';
import { TDropDownListElement } from '../../../ui/DropDownList/DropDownList';
import { updateUserLocation } from '../../../../api/updateUserLocation';
import { getMappedLocationSuggestions } from './getMappedLocationSuggestions';
import { getErrorMsgTranslationKey } from '../../../../utils/getErrorMsgTranslationKey';
import { AutocompleterSingleChoice } from '../../../ui/Form/AutocompleterSingleChoice/AutocompleterSingleChoice';
import { Logger } from '../../../../class/Logger';
import { TControlsRender } from '../TControlsRender';
import { FormControls } from '../FormControls/FormControls';
import { FlashMessage } from '../../../ui/FlashMessage/FlashMessage';
import { getFormErrorMsg } from '../formUtils/getFormErrorMsg';
import { TUpdateFieldFn, updateFieldErrors } from '../formUtils/updateFieldErrors';

interface IUserLocation {
    afterCancel?: () => void,
    afterSave?: () => void,
    controlsRender?: TControlsRender
}

const UserLocationForm: React.FC<IUserLocation> = ({ afterSave, afterCancel, controlsRender }) => {
    const { t } = useTranslation();
    const [ selectedLocation, updateSelectedLocation ] = useState<TUserLocation | null>(null);
    const [ locationList, updateLocationList ] = useState<TUserLocation[]>([]);
    const [ inputErrorMsg, updateInputErrorMsg ] = useState('');
    const [ isSaving, updateIsSaving ] = useState(false);
    const [ formError, updateFormError ] = useState('');

    const getUserLocation = async (
        query: string,
        updateAutocompleterListFn: Dispatch<SetStateAction<TDropDownListElement[]>>
    ) => {
        try {
            const locationSuggestions = await getUserLocationSuggestions(query);
            updateLocationList(locationSuggestions as TUserLocation[]);

            const mappedLocationsSuggestions = getMappedLocationSuggestions(locationSuggestions as TUserLocation[]);
            updateAutocompleterListFn(mappedLocationsSuggestions)
        } catch(error) {
            if (error.message === 'Request failed with status code 404') {
                updateInputErrorMsg(t('onBoarding:errors:locationNotFound'));
            } else {
                const logger = Logger.getInstance();
                logger.logError('[Location Autocompleter] Error while getting location suggestions ', error);
                const errorMsgTranslationKey = getErrorMsgTranslationKey(error.message);
                updateInputErrorMsg(t(errorMsgTranslationKey))
            }
        }
    };

    const handlers = {
        onSuggestionAccept: (acceptedLocation: TDropDownListElement): void => {
            updateInputErrorMsg('');
            const newLocation = locationList.find(element => {
                return element.id === acceptedLocation.id
            });

            if(newLocation) {
                updateSelectedLocation(newLocation);
            }
        },
        onSuggestionClear: (): void => {
            updateInputErrorMsg('');
            updateSelectedLocation(null);
        },
        onSubmit: async (event: FormEvent): Promise<void> => {
            event.preventDefault();

            if( selectedLocation ) {
                updateIsSaving(true);

                updateFormError('');

                try {
                    const response = await updateUserLocation(selectedLocation);
                    if( response === 'success') {
                        if (afterSave) {
                            afterSave();
                        }
                    } else {
                        throw response;
                    }
                } catch (error) {
                    const { message, failedFields } = getFormErrorMsg(error, t);

                    updateFormError(message);

                    const msgUpdate: TUpdateFieldFn = (fieldName: string, fieldMsg: string) => {
                        // Ignorujemy fieldName bo mamy tylko jedno pole
                        updateInputErrorMsg(fieldMsg);
                    };
                    updateFieldErrors([ 'location' ], failedFields, msgUpdate);
                }

                updateIsSaving(false);
            } else {
                updateInputErrorMsg(t('onBoarding:errors:noLocationAdded'))
            }
        },
        onCancel: () => {
            //TODO: Zrobic dla profilu
            if (afterCancel) {
                afterCancel()
            };
        },
        onAccept: (event: MouseEvent<HTMLButtonElement>) => {
            //TODO: Zrobic dla profilu
        }
    };

    const ControlsRender: React.FC = controlsRender ?
        controlsRender(handlers.onAccept, handlers.onCancel, isSaving)
        :
        () => (
            <FormControls
                onCancel={handlers.onCancel}
                onAccept={handlers.onAccept}
                isLoading={isSaving}
            />
        );

    return (
        <form onSubmit={handlers.onSubmit}>
            <AutocompleterSingleChoice
                name="user-location"
                className="user-location-form__autocompleter"
                textInputData={{
                    placeholder: '',
                    minimumLength: 2,
                    errorMsg: {
                        errorMsg: inputErrorMsg,
                        updateErrorMsg: updateInputErrorMsg
                    },
                }}
                onSuggestionAccept={handlers.onSuggestionAccept}
                onClearAcceptedSuggestion={handlers.onSuggestionClear}
                getAutocompleterSuggestions={getUserLocation}
            />
            {formError &&
                <FlashMessage type="error" message={formError} />
            }
            <div className="user-experience-form__form-controls">
                <ControlsRender />
            </div>
        </form>
    );
};

export { UserLocationForm, IUserLocation };
