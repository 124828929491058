import './DropDownListItem.scss'

import * as React from 'react';
import { getClassList } from '../../../../utils/getClassList';

interface IDropDownListItem {
    elementClick: () => void;
    selectItem: () => void;
    isSelected: boolean
}

const DropDownListItem: React.FC<IDropDownListItem> = ({ children, elementClick, selectItem, isSelected }) => {
    const elementClickHandler = (): void => {
        elementClick();
    };

    const onElementHover = (): void => {
        selectItem();
    };

    const elementClassList = getClassList([
        'dropdown-list-item',
        isSelected ? 'dropdown-list-item--selected' : ''
    ]);

    return (
        <li
            className={elementClassList}
            onClick={elementClickHandler}
            onMouseEnter={onElementHover}
        >
            {children}
        </li>
    )
};

export { DropDownListItem, IDropDownListItem };
