import './FormField.scss';
import * as React from 'react';
import { getClassList } from '../../../utils/getClassList';

interface IFormField {
    name: string;
    label?: string;
    errorMsg?: string;
    className?: string;
}

const FormField: React.FC<IFormField> = ({ children, label, name, className, errorMsg }) => {
    const classList = getClassList([
        'form-field',
        className && className
    ]);

    return (
        <div className={classList}>
            {label && (
                <label
                    htmlFor={name}
                    className="form-field__label"
                >
                    {label}
                </label>
            )}
            <div className="form-field__input">
                {children}
            </div>
            {errorMsg && (
                <span className="form-field__error-msg">
                    {errorMsg}
                </span>
            )}
        </div>
    );
};

export { FormField, IFormField };
