import { TUserLocation } from '../../../../api/getUserLocationSuggestions/getUserLocationSuggestions';
import { TDropDownListElement } from '../../../ui/DropDownList/DropDownList';

const getMappedLocationSuggestions = (locationSuggestions: TUserLocation[]): TDropDownListElement[] => {
    return locationSuggestions.map(location => {
        const { id, city, region, country } = location;

        const regionExtension = region !== null ? `, ${ region }` : '';
        const countryExtension = country !== null ? `, ${ country }` : '';

        const cityExtension = regionExtension + countryExtension;

        return {
            name: city,
            id: id,
            nameExtension: cityExtension
        }
    })
};

export { getMappedLocationSuggestions }
