import axios from 'axios';
import { getCsrfToken } from './getCsrfToken/getCsrfToken';
import { getApiResponse } from './apiUtils/getApiResponse';
import { GoldenError } from '../class/GoldenError';
import { getApiUrl } from './apiUtils/getApiUrl';

type TAddExperience = {
    companyName: string;
    position: string;
    startDate: {
        year: number;
        month: number;
    };
    endDate: null | {
        year: number;
        month: number;
    };
    duty: string;
}

async function addExperience(data: TAddExperience): Promise<boolean> {
    try {
        const token = await getCsrfToken();
        const url = getApiUrl('/user-profile/experience/add');
        const experiencePostData = {
            ...data,
            csrfActionToken: token
        };
        const addExperiencePost = await axios.post(
            url,
            experiencePostData,
            {
                withCredentials: true
            }
        );
        const addExperienceResponse = getApiResponse<null>(addExperiencePost);

        if (addExperienceResponse.isSuccess) {
            return true;
        } else {
            throw addExperienceResponse.response
        }
    } catch (error) {
        if (error.message === 'Network Error') {
            throw new GoldenError(
                'Network Error',
                '',
                'globals:errors:error.networkError'
            )
        }

        throw error;
    }
}

export { addExperience };
