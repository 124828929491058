import { pushGtmEvent } from '../../../utils/pushGtmEvent';
import { getEventDate } from '../../../utils/getEventDate';

function pushStepSaveEvent(stepNumber: number): void {
    pushGtmEvent(
        'Onboarding',
        'StepSave',
        `SaveStep${ stepNumber }`,
        getEventDate(),
        false
    )
}

function pushStepSkipEvent(stepNumber: number): void {
    pushGtmEvent(
        'Onboarding',
        'StepSkip',
        `SkipStep${ stepNumber }`,
        getEventDate(),
        false
    );
}

function pushStepViewEvent(stepNumber: number): void {
    pushGtmEvent(
        'Onboarding',
        'View',
        `Step${ stepNumber }`,
        getEventDate(),
        true
    );
}

function pushSiteExitEvent(stepNumber: number): void {
    pushGtmEvent(
        'Onboarding',
        'SiteExit',
        `SiteExitOnStep${ stepNumber }`,
        getEventDate(),
        false
    );
}

export { pushStepSaveEvent, pushStepSkipEvent, pushStepViewEvent, pushSiteExitEvent }
