import { useCallback, useEffect, useState } from 'react';
import { getFormDefaultState } from '../../utils/getFormDefaultState';

type TFormConfig = {
    [key: string]: TFormField<any>
}

type TFormField<T> = {
    value: T;
    validateFn: TValidateFn | TDependentValidation | null
    validateMsg: string;
}

type TValidateFn = (value: any) => boolean
type TDependentValidation = {
    checkFn: (value: any) => boolean;
    shouldCheckWhenField: {
        name: string;
        value: any;
    };
}

interface IUseSimpleForm {
    formFields: TFormState;
    updateField: TUpdateField;
    updateError: TUpdateError;
    updateFormFields: (newState: object) => void;
    validateFields: (ememailDbCheck?: boolean) => void;
    anyFieldFailed: () => boolean;
}

type TFormState = {[key: string]: any};
type TUpdateField = (fieldName: string, newValue: any) => void;
type TUpdateError = (fieldName: string, newMsg: string) => void;

function useSimpleForm(formConfig: TFormConfig): IUseSimpleForm {
    const defaultState = getFormDefaultState(formConfig);
    const [ formFields, updateFormFields ] = useState<TFormState>(defaultState);
    const formKeys = Object.keys(formConfig);

    const hasDependentValidation = (fieldName: string) => typeof formConfig[fieldName].validateFn !== 'function' && Boolean(formConfig[fieldName].validateFn);
    const isNotRequired = (fieldName: string) => formConfig[fieldName].validateFn === null;
    const passField = (formState: TFormState, fieldName: string): void => {
        formState[`${ fieldName }Pass`] = true;
        formState[`${ fieldName }ErrorMsg`] = '';
    };
    const failField = (formState: TFormState, fieldName: string): void => {
        const errorMsg = formConfig[fieldName].validateMsg;
        formState[`${ fieldName }Pass`] = false;
        formState[`${ fieldName }ErrorMsg`] = errorMsg;
    };

    const updateError: TUpdateError = (fieldName: string, newMsg: string): void => {
        const newState = JSON.parse(JSON.stringify(formFields));

        newState[`${ fieldName }ErrorMsg`] = newMsg;

        updateFormFields(newState);
    };

    const validationPassed = (fieldName: string, newValue: any): boolean => {
        if (hasDependentValidation(fieldName)) {
            const validateFn = formConfig[fieldName].validateFn as TDependentValidation;

            const { name, value } = validateFn.shouldCheckWhenField;
            const shouldCheck = formFields[name] === value;

            return shouldCheck ? validateFn.checkFn(newValue) : true;
        } else if (isNotRequired(fieldName)) {
            return true;
        } else {
            const validateFn = formConfig[fieldName].validateFn as TValidateFn;

            return validateFn(newValue);
        }
    };

    const updateField: TUpdateField = (fieldName: string, newValue: any): void => {
        const newState: TFormState = JSON.parse(JSON.stringify(formFields));

        for (const formKey of formKeys) {
            if (formKey === fieldName) {
                newState[formKey] = newValue;

                if (validationPassed(fieldName, newValue)) {
                    passField(newState, formKey);
                } else {
                    failField(newState, formKey);
                }
            }

            if (hasDependentValidation(formKey)) {
                const validateFn = formConfig[formKey].validateFn as TDependentValidation;
                const { shouldCheckWhenField } = validateFn;
                if (shouldCheckWhenField.name === fieldName) {
                    passField(newState, formKey);
                }
            }
        }

        updateFormFields(newState);
    };

    const validateFields = (emailDbCheck?: boolean) => {
        const newState: TFormState = JSON.parse(JSON.stringify(formFields));

        for (const formKey of formKeys) {
            const currentValue = newState[formKey];
            const validationHasPassed = formKey === 'email' && emailDbCheck ? newState[`${ formKey }Pass`] : validationPassed(formKey, currentValue);

            if (validationHasPassed) {
                passField(newState, formKey);
            } else {
                failField(newState, formKey);
            }
        }

        updateFormFields(newState);
    };

    const anyFieldFailed = () => {
        return formKeys.some((fieldName) => {
            return !formFields[`${ fieldName }Pass`]
        });
    };

    const passNonRequiredFields = useCallback((): void => {
        const newState: TFormState = JSON.parse(JSON.stringify(formFields));

        for (const formKey of formKeys) {
            if (formKey && formConfig[formKey].validateFn === null) {
                passField(newState, formKey);
            }
        }

        updateFormFields(newState);
    }, []);

    useEffect(() => {
        passNonRequiredFields();
    }, []);

    return {
        formFields: formFields,
        updateField: updateField,
        updateError: updateError,
        updateFormFields: updateFormFields,
        validateFields: validateFields,
        anyFieldFailed: anyFieldFailed
    };
}

export { useSimpleForm, TFormField, TFormConfig, TDependentValidation };
