import './UserExperienceForm.scss';
import * as React from 'react';
import { FormEvent, useState } from 'react';

import { MonthSelect } from '../../formFields/MonthSelect/MonthSelect';
import { FormField } from '../../formFields/FormField';
import { useTranslation } from 'react-i18next';
import { YearSelect } from '../../formFields/YearSelect/YearSelect';
import { TDependentValidation, TFormConfig, useSimpleForm } from '../../../hooks/useSimpleForm';
import { FirmAutocompleter } from '../../formFields/FirmAutocompleter/FirmAutocompleter';
import { PositionAutocompleter } from '../../formFields/PositionAutocompleter/PositionAutocompleter';
import { CheckboxField } from '../../../ui/Form/CheckboxField/CheckboxField';
import { TextArea } from '../../../ui/Form/TextArea/TextArea';
import { addExperience } from '../../../../api/addExperience';
import { FlashMessage } from '../../../ui/FlashMessage/FlashMessage';
import { TControlsRender } from '../TControlsRender';
import { getFormErrorMsg } from '../formUtils/getFormErrorMsg';
import { TUpdateFieldFn, updateFieldErrors } from '../formUtils/updateFieldErrors';
import { FormControls } from '../FormControls/FormControls';

interface IUserExperienceForm {
    controlsRender?: TControlsRender;
    afterSave?: () => void;
    withDuty?: boolean;
    error?: string;
}

const UserExperienceForm: React.FC<IUserExperienceForm> = ({ controlsRender, afterSave, withDuty }) => {
    const { t } = useTranslation();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ formError, updateFormError ] = useState('');

    const endTimeValidation: TDependentValidation = {
        checkFn: (value): boolean => value != '',
        shouldCheckWhenField: {
            name: 'stillWorkHere',
            value: false
        }
    };
    const formConfig: TFormConfig = {
        firmName: {
            value: '',
            validateFn: value => value.length > 2,
            validateMsg: t('editExperience:validateMsg:firmName')
        },
        position: {
            value: '',
            validateFn: value => value.length > 2,
            validateMsg: t('editExperience:validateMsg:position')
        },
        startYear: {
            value: '',
            validateFn: value => value != '',
            validateMsg: t('editExperience:validateMsg:year')
        },
        endYear: {
            value: '',
            validateFn: endTimeValidation,
            validateMsg: t('editExperience:validateMsg:year')
        },
        startMonth: {
            value: '',
            validateFn: value => value != '',
            validateMsg: t('editExperience:validateMsg:month')
        },
        endMonth: {
            value: '',
            validateFn: endTimeValidation,
            validateMsg: t('editExperience:validateMsg:month')
        },
        stillWorkHere: {
            value: false,
            validateFn: null,
            validateMsg: ''
        },
        duty: {
            value: '',
            validateFn: null,
            validateMsg: ''
        }
    };
    const { formFields, updateField, updateError, validateFields, anyFieldFailed } = useSimpleForm(formConfig);

    const handlers = {
        firm: {
            onAccept: (newElement: string): void => {
                updateField('firmName', newElement);
            },
            onClear: (): void => {
                updateField('firmName', '');
            },
            updateError: (newMsg: string): void => {
                updateError('firmName', newMsg);
            }
        },
        position: {
            onAccept: (newElement: string): void => {
                updateField('position', newElement);
            },
            onClear: (): void => {
                updateField('position', '');
            },
            updateError: (newMsg: string): void => {
                updateError('position', newMsg);
            }
        },
        startYear: (newValue: number): void => {
            updateField('startYear', newValue);
        },
        startMonth: (newValue: number): void => {
            updateField('startMonth', newValue);
        },
        endYear: (newValue: number): void => {
            updateField('endYear', newValue);
        },
        endMonth: (newValue: number): void => {
            updateField('endMonth', newValue);
        },
        stillWorkHere: {
            onClick: (): void => {
                updateField('stillWorkHere', !formFields['stillWorkHere']);
            }
        },
        form: {
            onSubmit: async (event: FormEvent): Promise<void> => {
                event.preventDefault();
                validateFields();

                if (anyFieldFailed()) {
                    updateFormError(t('editExperience:validationError'))
                } else {
                    setIsLoading(true);

                    updateFormError('');

                    try {

                        const addExperienceAction = await addExperience({
                            companyName: formFields['firmName'],
                            position: formFields['position'],
                            startDate: {
                                year: formFields['startYear'],
                                month: formFields['startMonth']
                            },
                            endDate: formFields['stillWorkHere'] ? null : {
                                year: formFields['endYear'],
                                month: formFields['endMonth']
                            },
                            duty: formFields['duty']
                        });

                        if (addExperienceAction) {
                            // Success
                            if (afterSave) {
                                afterSave();
                            }
                        }
                    } catch (error) {
                        const { message, failedFields } = getFormErrorMsg(error, t);

                        updateFormError(message);

                        const formKeys = Object.keys(formConfig);
                        const updateMsg: TUpdateFieldFn = (fieldName, fieldMsg) => {
                            updateError(fieldName, fieldMsg);
                        };

                        updateFieldErrors(formKeys, failedFields, updateMsg);
                    }

                    setIsLoading(false);
                }
            },
            onCancel: (): void => {},
            onSubmitBtnClick: (): void => {}
        },
        duty: {
            onChange: (newValue: string) => {
                updateField('duty', newValue);
            }
        }
    };

    const ControlsRender: React.FC = controlsRender ?
        controlsRender(handlers.form.onSubmitBtnClick, handlers.form.onCancel, isLoading)
        : () => (
            <FormControls
                onCancel={handlers.form.onCancel}
                onAccept={handlers.form.onSubmitBtnClick}
                isLoading={isLoading}
            />
        );

    return (
        <form
            className="user-experience-form"
            onSubmit={handlers.form.onSubmit}
        >
            <div className="user-experience-form__fields">
                <FormField
                    name="firm-name"
                    label={t('editExperience:firmName')}
                    className="user-experience-form__field"
                >
                    <FirmAutocompleter
                        onAccept={handlers.firm.onAccept}
                        onClear={handlers.firm.onClear}
                        errorMsg={formFields['firmNameErrorMsg']}
                        updateErrorMsg={handlers.firm.updateError}
                        className="user-experience-form__autocompleter"
                    />
                </FormField>
                <FormField
                    name="position"
                    label={t('editExperience:position')}
                    className="user-experience-form__field"
                >
                    <PositionAutocompleter
                        onAccept={handlers.position.onAccept}
                        onClear={handlers.position.onClear}
                        errorMsg={formFields['positionErrorMsg']}
                        updateErrorMsg={handlers.position.updateError}
                        className="user-experience-form__autocompleter"
                    />
                </FormField>
                <div className="user-experience-form__time-fields-wrap">
                    <div className="user-experience-form__dates">
                        <FormField
                            name="job-start"
                            label={t('editExperience:startJobDate')}
                            className="user-experience-form__field"
                        >
                            <div className="user-experience-form__time-fields user-experience-form__time-fields--start">
                                <FormField
                                    name="start-month"
                                    className="user-experience-form__time-select"
                                    errorMsg={formFields['startMonthErrorMsg']}
                                >
                                    <MonthSelect
                                        name="start-month"
                                        onChange={handlers.startMonth}
                                        error={formFields['startMonthErrorMsg'] !== ''}
                                    />
                                </FormField>
                                <FormField
                                    name="start-year"
                                    className="user-experience-form__time-select"
                                    errorMsg={formFields['startYearErrorMsg']}
                                >
                                    <YearSelect
                                        name="start-year"
                                        onChange={handlers.startYear}
                                        error={formFields['startYearErrorMsg'] !== ''}
                                    />
                                </FormField>
                            </div>
                        </FormField>
                        {!formFields['stillWorkHere'] && (
                            <FormField
                                name="job-end"
                                label={t('editExperience:endJobDate')}
                                className="user-experience-form__field"
                            >
                                <div className="user-experience-form__time-fields user-experience-form__time-fields--end">
                                    <FormField
                                        name="end-month"
                                        className="user-experience-form__time-select"
                                        errorMsg={formFields['endMonthErrorMsg']}
                                    >
                                        <MonthSelect
                                            name="end-month"
                                            onChange={handlers.endMonth}
                                            error={formFields['endMonthErrorMsg'] !== ''}
                                        />
                                    </FormField>
                                    <FormField
                                        name="end-year"
                                        className="user-experience-form__time-select"
                                        errorMsg={formFields['endYearErrorMsg']}
                                    >
                                        <YearSelect
                                            name="end-year"
                                            onChange={handlers.endYear}
                                            error={formFields['endYearErrorMsg'] !== ''}
                                        />
                                    </FormField>
                                </div>
                            </FormField>
                        )}
                    </div>
                    <FormField
                        name="still-work-here"
                        className="user-experience-form__checkbox"
                    >
                        <CheckboxField
                            name="still-work-here"
                            checked={formFields['stillWorkHere']}
                            value={formFields['stillWorkHere']}
                            actions={handlers.stillWorkHere}
                            label={t('editExperience:stillWorkHere')}
                        />
                    </FormField>
                </div>
                {withDuty && (
                    <FormField
                        name="duty"
                        label={t('editExperience:duty')}
                        className="user-experience-form__field"
                    >
                        <TextArea
                            name="duty"
                            actions={handlers.duty}
                            value={formFields['duty']}
                            className="user-experience-form__duty"
                        />
                    </FormField>
                )}
            </div>
            {formError &&
                <FlashMessage type="error" message={formError} />
            }
            <div className="user-experience-form__form-controls">
                <ControlsRender />
            </div>
        </form>
    );
};

export { UserExperienceForm, TControlsRender };
