import axios from 'axios';
import { getApiUrl } from '../apiUtils/getApiUrl';

type TUserLocation = {
    id: string | number,
    city: string,
    region: string | null,
    country: string | null,
    isNew: boolean
}

const getUserLocationSuggestions = async (
    query: string,
): Promise<TUserLocation[] | string> => {
    const url = getApiUrl('/narzedzia/location/finder');
    const params = {
        strict: 1,
        city: query
    };

    const response = await axios.get(url, { params: params, withCredentials: true });

    return response.data as TUserLocation[];
};

export { getUserLocationSuggestions, TUserLocation }
