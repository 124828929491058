import { getEnvironment, EnvironmentType } from "../../utils/environment";

const getApiUrl = (url: string) => {
    const environment = getEnvironment();
    let domain = '';

    switch (environment) {
        case EnvironmentType.PROD:
            domain = 'https://www.goldenline.pl';
            break;
        case EnvironmentType.TESTING:
            domain = 'https://www-arak.goldenline.pl';
            break;
        case EnvironmentType.DEV:
            domain = 'https://www.goldenline.pl.127.0.0.1.xip.io';
            break;
    }

    return domain + url
};

export { getApiUrl };
