import './DropDownList.scss'
import * as React from 'react';

import { DropDownListItem } from './DropDownListItem/DropDownListItem';
import { WhiteBox } from '../../layout/WhiteBox/WhiteBox';
import { getClassList } from '../../../utils/getClassList';
import { useStyledScrollbar } from '../../hooks/useStyledScrollbar/useStyledScrollbar';

interface IDropDownList {
    name: string;
    selectedElement: number;
    selectElement: (index: number) => void;
    elementClick: () => void;
    list: TDropDownListElement[];
    resetInput?: () => void;
    /**
     * prekazanie thruthy lastElement jest warunkiem do pokazania ostatniego elementu
     */
    customElement?: JSX.Element | null;
    className?: string;
    visible?: boolean
};

type TDropDownListElement = {
    name: string,
    id: number | string | null,
    nameExtension?: string
};

const DropDownList: React.FC<IDropDownList> = ({ list, customElement, elementClick, name, selectElement, resetInput, className, selectedElement, visible }) => {
    const isLastElementSelected = selectedElement === list.length;

    useStyledScrollbar(
        '.dropdown-list'
    );

    const classList = getClassList([
        'dropdown-list',
        className ? className : '',
        visible ? 'dropdown-list--visible' : 'dropdown-list--hidden',
        'dropdown-list--' + name
    ]);

    const customElementClassList = getClassList([
        'dropdown-list-item',
        'dropdown-list-item--custom',
        isLastElementSelected ? 'dropdown-list-item--selected' : ''
    ]);

    return (
        <WhiteBox className={classList}>
            <ul className="dropdown-list__list">
                {list.map((element, index) => {
                    const elementClickHandler = (): void => {
                        elementClick();
                        if (resetInput) {
                            resetInput();
                        }
                    };

                    const elementHoverHandler = (): void => {

                        selectElement(index)
                    };

                    return (
                        <DropDownListItem
                            key={element.name.toLowerCase() + element.id}
                            selectItem={elementHoverHandler}
                            elementClick={elementClickHandler}
                            isSelected={selectedElement === index}
                        >
                            {element.name}
                            {element.nameExtension &&
                                <span className="dropdown-list-item__name-extension">
                                    {element.nameExtension}
                                </span>
                            }
                        </DropDownListItem>
                    )
                })}
                {customElement && (
                    <li
                        className={customElementClassList}
                    >
                        {customElement}
                    </li>
                )}
            </ul>
        </WhiteBox>
    )
};

export { DropDownList, TDropDownListElement }
