import { useEffect } from 'react';

/**
 * klasa elementu, który otrzymał focus
 * @string focusedElementClassName
 *
 * Lista klas CSS, w które kliknięcie nie spowoduje blura
 * @string ignoredClassNames
 *
 * funkcja sprawdzająca czy kontener jest obecnie widoczny
 * @function visibilityCheckFn
 *
 * funkcja odpalana jeżeli użytkownik kliknie poza ignorowane elementy
 * @function onBlurCallback
 */

const useCustomElementBlur = ( focusedElementClassName: string, ignoredClassNames: string[], visibilityCheckFn: () => boolean, onBlurCallback: () => void ): void => {
    useEffect(() => {
        const focusedElement = document.querySelector(`.${ focusedElementClassName }`);
        if(focusedElement) {
            focusedElement.addEventListener('keydown', (event: KeyboardEvent) => {
                if(event.key === 'Tab') {
                    onBlurCallback()
                }
            });
        }
        document.addEventListener('click', (event: MouseEvent & { path: HTMLElement[] }) => {
            if( visibilityCheckFn && visibilityCheckFn() ){
                const eventPathElements = event.path as HTMLElement[];

                const classNamesInPath: boolean[] =
                    ignoredClassNames.map(className => {
                        return eventPathElements.some(element => {
                            return element.classList && element.classList.contains(className)
                        })
                    });

                const isAnyClassNameInPath = classNamesInPath.some(el => el === true);

                if (!isAnyClassNameInPath) {
                    onBlurCallback()
                }
            }
        });
    }, [])
};

export { useCustomElementBlur }
