import './SingleChoiceItem.scss';
import * as React from 'react';
import { IconClear } from '../../../../../../assets/icons/IconClear';
import { getClassList } from '../../../../../utils/getClassList';

interface ISingleChoiceItem {
    name: string;
    onRemove?: () => void;
    nameExtension?: string;
    errorMsg?: string;
}

const SingleChoiceItem: React.FC<ISingleChoiceItem> = ({ name, onRemove, nameExtension, errorMsg }) => {
    const classList = getClassList([
        'single-choice-item__name-wrap',
        errorMsg ? 'single-choice-item__name-wrap--error' : ''
    ]);

    return (
        <div className="single-choice-item">
            <div className={classList} onClick={onRemove}>
                <div className="single-choice-item__name">
                    { name }
                    { nameExtension &&
                    <span className="single-choice-item__name-extension">
                        { nameExtension }
                    </span>
                    }
                </div>
                { onRemove &&
                    <button type="button" className="single-choice-item__clear">
                        <IconClear />
                    </button>
                }
            </div>
            {errorMsg && (
                <span className="single-choice-item__error-msg">
                    {errorMsg}
                </span>
            )}
        </div>
    );
};

export { SingleChoiceItem, ISingleChoiceItem };
