import './OnboardingStep.scss';
import * as React from 'react';

import { getClassList } from '../../../../utils/getClassList';
import { useEffect } from 'react';
import { pushStepViewEvent } from '../gtmEvents';

interface IOnboardingStep {
    headline: string;
    stepNumber: number;
    isActive: boolean;
}

const OnboardingStep: React.FC<IOnboardingStep> = ({ children, headline, stepNumber, isActive }) => {
    const classList = getClassList([
        'onboarding-step',
        isActive ? 'onboarding-step--visible' : 'onboarding-step--hidden'
    ]);

    useEffect(() => {
        if (isActive) {
            pushStepViewEvent(stepNumber);
        }
    }, [ isActive, stepNumber ]);

    return (
        <section className={classList} tabIndex={-1}>
            <div className="onboarding-step__content">
                <div className="onboarding-step__headline">
                    {headline}
                </div>
                {children}
            </div>
        </section>
    );
};

export { OnboardingStep, IOnboardingStep };
