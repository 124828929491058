import * as React from 'react';
import { Context, useEffect, useState } from 'react';
import { IWindow } from '../../typings/IWindow';
import { getIsOnboardingFinished } from '../../api/getIsOnboardingFinished';

interface IGlobalContextProvider {
    lang?: string;
    loggedIn?: boolean;
    environment?: string;
}

interface IGlobalContext extends IGlobalContextProvider{
    path: string;
    isOnboardingFinished: boolean | null;
}

declare const window: IWindow & Window;

const defaultGlobalContext: IGlobalContext = {
    lang: 'pl',
    path: '/',
    /**
     * zakładamy, że user zakończył onboarding, po zamontowaniu odpytamy czy trzeba pokazać
     */
    isOnboardingFinished: true,
    environment: '',
    loggedIn: false
};

const GlobalContext: Context<IGlobalContext> = React.createContext(defaultGlobalContext);

const GlobalContextProvider: React.FC<IGlobalContextProvider> = ({ children, environment, lang, loggedIn }) => {
    const [ globalContextValue, setGlobalContextValue ] = useState<IGlobalContext>(defaultGlobalContext);

    const setContextValue = async (): Promise<void> => {
        const isUserLoggedIn = typeof loggedIn !== 'undefined' ? loggedIn : Object.keys(window.CURRENT).length !== 0;
        const currentEnvironment = environment ? environment : window.ENVIRONMENT;
        const localeFromWindow = window.LOCALE ? window.LOCALE : 'pl';
        const currentLang = lang ? lang : localeFromWindow;

        setGlobalContextValue({
            lang: currentLang,
            path: window.location.pathname,
            isOnboardingFinished: isUserLoggedIn ? await getIsOnboardingFinished() : null,
            environment: currentEnvironment,
            loggedIn: isUserLoggedIn
        });
    };

    useEffect(() => {
        setContextValue();
    }, []);

    return (
        <GlobalContext.Provider value={globalContextValue}>
            {children}
        </GlobalContext.Provider>
    );
};

export { GlobalContext, GlobalContextProvider, IGlobalContext };
