import './FlashMessage.scss';
import * as React from 'react';
import { useState } from 'react';

import { WhiteBox } from '../../layout/WhiteBox/WhiteBox';
import { CloseButton } from '../Buttons/CloseButton/CloseButton';

interface IFlashMessage {
    type: string;
    message: string;
}

const FlashMessage: React.FC<IFlashMessage> = ({ type, message }) => {
    const [ messageVisible, setMessageVisibility ] = useState(true);

    const classList = [
        'flash-message',
        messageVisible ? 'flash-message--visible' : 'flash-message--hidden',
        type === 'error' ? 'flash-message--error' : 'flash-message--info'
    ].join(' ');

    const hideMessage = () => {
        setMessageVisibility(false);
    };

    return (
        <WhiteBox className={classList}>
            <div className="flash-message__text">
                {message}
            </div>
            <CloseButton className="flash-message__close-btn" onClick={hideMessage} />
        </WhiteBox>
    );
};

export { FlashMessage, IFlashMessage };
