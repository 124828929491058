import { EGoldenErrorCodes, GoldenError } from '../../../../class/GoldenError';
import { ValidationError } from '../../../../class/ValidationError';
import { TFunction } from 'i18next';
import { TFormError } from '../../../../api/apiUtils/getApiResponse';

type TErrorMsg = {
    message: string,
    failedFields: TFormError[]
}

function getFormErrorMsg(error: GoldenError, t: TFunction): TErrorMsg {
    const { translationKey } = error;

    const errorMsg: TErrorMsg = {
        message: t(`${ translationKey }`),
        failedFields: []
    };

    if (error.errorCode === EGoldenErrorCodes.VALIDATION) {
        const { validationErrors } = error as ValidationError;
        errorMsg.failedFields = validationErrors.length > 0 ? validationErrors : [];
    }

    return errorMsg;
}

export { getFormErrorMsg }
