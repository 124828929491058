import axios from 'axios'

import { getApiResponse, TGoldenAppResult } from './apiUtils/getApiResponse';
import { Logger } from '../class/Logger';
import { getApiUrl } from './apiUtils/getApiUrl';

async function getIsOnboardingFinished(): Promise<boolean | null> {
    const url = getApiUrl('/user-profile/onboarding/check');

    try {
        const onboardingCheckGet = await axios.get(url, { withCredentials: true });
        const result = getApiResponse<boolean>(onboardingCheckGet);

        if (result.isSuccess) {
            // eslint-disable-next-line no-extra-parens
            const { data } = (result.response as TGoldenAppResult<boolean>).result;
            return data;
        } else {
            throw result.response;
        }
    } catch (error) {
        const logger = Logger.getInstance();
        logger.logError('[getIsOnboardingFinished]', error);
        return null;
    }
}

export { getIsOnboardingFinished };
