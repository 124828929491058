import './TextArea.scss';
import * as React from 'react';
import { ChangeEvent } from 'react';
import { useState } from 'react';
import { getClassList } from '../../../../utils/getClassList';

interface ITextArea {
    name: string;
    actions: TTextAreaActions;
    value: string;
    label?: string;
    errorMsg?: string;
    disabled?: boolean;
    className?: string;
}

type TTextAreaActions = {
    onChange?: (newValue: string) => void,
    onChangeEvent?: (event: React.ChangeEvent) => void,
    onFocus?: () => void,
    onBlur?: () => void
}

const TextArea: React.FC<ITextArea> = ({ name, actions, value, label, errorMsg, disabled, className }) => {
    const [ areaStatus, setAreaStatus ] = useState('');

    const classList = getClassList([
        'text-area',
        areaStatus === 'focus' ? 'text-area--focus' : '',
        disabled ? 'text-area--disabled' : '',
        errorMsg ? 'text-area--error' : ''
    ]);

    const handlers = {
        onFocus: (): void => {
            setAreaStatus('focus');
            actions.onFocus && actions.onFocus();
        },
        onChange: (event: ChangeEvent<HTMLTextAreaElement>): void => {
            actions.onChange && actions.onChange(event.target.value);
            actions.onChangeEvent && actions.onChangeEvent(event);
        },
        onBlur: (): void => {
            actions.onBlur && actions.onBlur();
        }
    };

    return (
        <div className={classList}>
            {label && (
                <label
                    className="text-area__label"
                    htmlFor={name}
                >
                    {label}
                </label>
            )}
            <textarea
                name={name}
                id={name}
                className={`text-area__input ${ className ? className : '' }`}
                onChange={handlers.onChange}
                onFocus={handlers.onFocus}
                onBlur={handlers.onBlur}
                value={value}
                disabled={disabled}
            />
            {errorMsg && (
                <span className="text-area__error-msg">
                    {errorMsg}
                </span>
            )}
        </div>

    );
};

export { TextArea, TTextAreaActions };
