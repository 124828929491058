import * as React from 'react';
import { ApiAutocompleter, IApiAutocompleter, TAddCustomItemsData } from '../ApiAutocompleter/ApiAutocompleter';
import { SingleChoiceItem } from './SingleChoiceItem/SingleChoiceItem';
import { TDropDownListElement } from '../../DropDownList/DropDownList';
import { useState } from 'react';

interface IAutocompleterSingleChoice extends IApiAutocompleter {
    /**
     * onClearAcceptedSuggestion usuwa wybrany element, jednocześnie powinna też obsługiwa błędy
     */
    onClearAcceptedSuggestion: () => void;
    addCustomItemsData?: TAddCustomItemsData;
    item?: TChosenItem;
}

type TChosenItem = {
    name: string;
    nameExtension: string;
}

const AutocompleterSingleChoice: React.FC<IAutocompleterSingleChoice> = ({ name, textInputData, onSuggestionAccept, getAutocompleterSuggestions, onClearAcceptedSuggestion, className, addCustomItemsData, item }) => {
    const initialItem = item ? item : null;
    const [ selectedItem, updateSelectedItem ] = useState<TChosenItem | null >(initialItem);

    const onClearSuggestion = (): void => {
        onClearAcceptedSuggestion();
        updateSelectedItem(null);
    };

    const onSuggestionAcceptAction = (acceptedElement: TDropDownListElement): void => {
        const newSelectedItem: TChosenItem = {
            name: acceptedElement.name,
            nameExtension: acceptedElement.nameExtension ? acceptedElement.nameExtension : ''
        };

        updateSelectedItem(newSelectedItem);
        onSuggestionAccept(acceptedElement)
    };

    return (
        <div className={className}>
            { selectedItem ? (
                <SingleChoiceItem
                    onRemove={onClearSuggestion}
                    errorMsg={textInputData.errorMsg.errorMsg}
                    {...selectedItem}
                />
            ) : (
                <ApiAutocompleter
                    name={name}
                    textInputData={textInputData}
                    onSuggestionAccept={onSuggestionAcceptAction}
                    getAutocompleterSuggestions={getAutocompleterSuggestions}
                    addCustomItemsData={addCustomItemsData}
                />
            )}
        </div>
    );
};

export { AutocompleterSingleChoice, IAutocompleterSingleChoice };
