import './ApiAutocompleter.scss';
import * as React from 'react';
import { Dispatch, SetStateAction, useEffect, useState, MutableRefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { DropDownList,TDropDownListElement } from '../../DropDownList/DropDownList';
import { useCustomElementBlur } from '../../../hooks/useCustomElementBlur';
import { getClassList } from '../../../../utils/getClassList';
import { CustomItemAdder } from './CustomItemAdder/CustomItemAdder';
import { AutocompleterInput, TAutocompleterInputActions } from './AutocompleterInput/AutocompleterInput';
import { TErrorMsg } from '../../../../typings/goldenApp';
import { getNewDropDownSelection } from '../../DropDownList/getNewDropDownSelection';

interface IApiAutocompleter {
    name: string;
    textInputData: {
        placeholder: string;
        minimumLength: number;
        errorMsg: TErrorMsg;
    };
    onSuggestionAccept: (element: string | object) => void;
    getAutocompleterSuggestions: TSuggestionListUpdateFn;
    className?: string;
    addCustomItemsData?: TAddCustomItemsData
}

type TAddCustomItemsData = {
    label: string;
    isNewItem: (query: string) => boolean
}

type TSuggestionListUpdateFn = (
    query: string,
    updateAutocompleterListFn: Dispatch<SetStateAction<TDropDownListElement[]>>
) => void;

const ApiAutocompleter: React.FC<IApiAutocompleter> = ({ name, textInputData, onSuggestionAccept, getAutocompleterSuggestions, className, addCustomItemsData }) => {
    const { t } = useTranslation();
    const [ query, updateQuery ] = useState('');
    const [ isFocused, updateIsFocused ] = useState(false);

    const [ suggestionList, updateSuggestionList ] = useState<TDropDownListElement[]>( []);
    const [ selectedElementIndex, updateSelectedElementIndex ] = useState(0);

    const [ isLoading, updateIsLoading ] = useState(false);
    const searchTimeout: MutableRefObject<number | undefined> = useRef();

    const { errorMsg, updateErrorMsg } = textInputData.errorMsg;

    const dropDownListRef: MutableRefObject<HTMLElement | null> = useRef(null);

    const resetAutocompleter = ():void => {
        updateSuggestionList([]);
        updateQuery('');

        updateIsLoading(false);
        window.clearTimeout(searchTimeout.current)
    };

    useEffect(() => {
        updateSuggestionList([]);
        window.clearTimeout(searchTimeout.current);

        searchTimeout.current = window.setTimeout(async () => {
            const isInputLongEnough = query.length >= textInputData.minimumLength;
            if (isInputLongEnough) {
                updateIsLoading(true);
                await getAutocompleterSuggestions(query, updateSuggestionList);
                updateIsLoading(false);
            }
        }, 400);
    }, [ query ]);

    const dropDownListUniqueClassName = 'api-autocompleter__dropdown--' + name;

    useEffect(() => {
        dropDownListRef.current = document.querySelector(`.${ dropDownListUniqueClassName }`)
    }, []);

    const canAcceptSuggestion = !isLoading && errorMsg.length === 0;
    const actions = {
        acceptSuggestion: (): void => {
            if (query.length < textInputData.minimumLength) {
                updateErrorMsg(t('globals:errors:inputValueNotLongEnough:inputValueNotLongEnough', { count: textInputData.minimumLength }))
            } else if (canAcceptSuggestion) {
                const isNewElementSelected = selectedElementIndex === suggestionList.length;

                if (isNewElementSelected) {
                    const newElement = {
                        name: query,
                        id: null
                    };
                    onSuggestionAccept(newElement)
                } else {
                    const selectedElement = suggestionList[selectedElementIndex];
                    onSuggestionAccept(selectedElement);
                }
                resetAutocompleter();
            }
        },
        selectSuggestion: (newSelectedElementIndex: number) => {
            updateSelectedElementIndex(newSelectedElementIndex);
        },
        selectCustomElement: () => {
            actions.selectSuggestion(suggestionList.length)
        }
    };

    //Blur
    const isDropdownVisible = (): boolean => {
        const dropDownListVisible = dropDownListRef.current ? dropDownListRef.current.classList.contains('api-autocompleter__dropdown--visible') : false;
        return dropDownListVisible
    };

    useCustomElementBlur(
        `autocompleter-text-field__input--${name}`,
        [ `api-autocompleter--${ name }` ],
        isDropdownVisible,
        () => {
            updateIsFocused(false);
        });

    const inputActions: TAutocompleterInputActions = {
        onFocus: () => {
            updateIsFocused(true);
        },
        onChange: async (newQuery: string) => {
            updateQuery(newQuery);
            updateErrorMsg('');
        }
    };

    const canType = errorMsg.length === 0 && !isLoading;
    const queryIsLongEnough = query.length >= textInputData.minimumLength;
    const isNewElement = addCustomItemsData && addCustomItemsData.isNewItem(query);
    const isInSuggestions = suggestionList.some( element => {
        return element.name.toLowerCase() === query.toLowerCase();
    });

    const canAddNewItems = queryIsLongEnough && isNewElement && !isInSuggestions && canType;
    const canChooseSuggestion = suggestionList.length > 0 && canType;

    const shouldShowDropdown = ( canAddNewItems || canChooseSuggestion ) && isFocused;
    const lastElementIndex = canAddNewItems ? suggestionList.length : suggestionList.length - 1 ;
    const isLastSelected = selectedElementIndex === lastElementIndex;

    const selectionActions = {
        down: (): void => {
            updateSelectedElementIndex(
                getNewDropDownSelection.down(selectedElementIndex, lastElementIndex, `.${dropDownListUniqueClassName}`)
            );
        },
        up: (): void => {
            updateSelectedElementIndex(
                getNewDropDownSelection.up(selectedElementIndex, lastElementIndex, `.${dropDownListUniqueClassName}`)
            );
        },
        accept: (): void => {
            actions.acceptSuggestion()
        }
    };

    const classList = getClassList(
        [
            'api-autocompleter',
            name ? `api-autocompleter--${ name }` : '',
            className ? className : ''
        ]
    );

    const dropDownListClassList = getClassList([
        'api-autocompleter__dropdown',
        dropDownListUniqueClassName,
        shouldShowDropdown ?
            'api-autocompleter__dropdown--visible' :
            'api-autocompleter__dropdown--hidden'
    ]);

    return (
        <div className={classList}>
            <div className="api-autocompleter__input-wrap">
                <AutocompleterInput
                    name={name}
                    value={query}
                    actions={inputActions}
                    selection={selectionActions}
                    minimumLength={textInputData.minimumLength}
                    isLoading={isLoading}
                    placeholder={textInputData.placeholder}
                    errorMsg={errorMsg}
                />
            </div>
            <DropDownList
                name={name}
                className={dropDownListClassList}
                selectedElement={selectedElementIndex}
                selectElement={actions.selectSuggestion}
                elementClick={actions.acceptSuggestion}
                list={suggestionList}
                resetInput={resetAutocompleter}
                visible={shouldShowDropdown}
                customElement={canAddNewItems && addCustomItemsData ? (
                    <CustomItemAdder
                        itemLabel={addCustomItemsData.label}
                        query={query}
                        onMouseEnter={actions.selectCustomElement}
                        onClick={actions.acceptSuggestion}
                        isSelected={isLastSelected}
                    />
                ) : null}
            />
        </div>
    );
};

export { ApiAutocompleter, IApiAutocompleter, TSuggestionListUpdateFn, TAddCustomItemsData };
