import i18n, { TOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationsEmailPl = require('./email.pl.json');
import translationsWebPl = require('./web.pl.json');
import translationsPathsPl = require('./paths.pl.json');
import translationsGlobalsPl = require('./globals.pl.json');
import translationsWebEn = require('./web.en.json');
import translationsEmailEn = require('./email.en.json');
import translationsPathsEn = require('./paths.en.json');
import translationsGlobalsEn = require('./globals.en.json');
import translationsWebUk = require('./web.uk.json');
import translationsEmailUk = require('./email.uk.json');
import translationsPathsUk = require('./paths.uk.json');
import translationsGlobalsUk = require('./globals.uk.json');

const i18nResources = {
    en: {
        web: translationsWebEn,
        email: translationsEmailEn,
        paths: translationsPathsEn,
        globals: translationsGlobalsEn
    },
    pl: {
        web: translationsWebPl,
        email: translationsEmailPl,
        paths: translationsPathsPl,
        globals: translationsGlobalsPl
    },
    uk: {
        web: translationsWebUk,
        email: translationsEmailUk,
        paths: translationsPathsUk,
        globals: translationsGlobalsUk
    }
};

const i18nOptions: TOptions = {
    resources: i18nResources,
    fallbackLng: 'pl',
    ns: [ 'web', 'globals', 'email', 'paths' ],
    defaultNS: 'web',
    keySeparator: ':',
    interpolation: {
        escapeValue: false
    }
};

const initI18n = (lang: string): void => {
    i18nOptions.lng = lang;
    i18n
        .use(initReactI18next)
        .init(i18nOptions);
};

export { initI18n, i18nOptions };
