import './LinkButton.scss';
import * as React from 'react';
import { getClassList } from '../../../../utils/getClassList';
import {IconLoading} from '../../../../../assets/icons/IconLoading';

interface ILinkButtonProps {
    /**
     * Daje nam "strzałkę"
     */
    arrowPosition?: EArrowPosition
    /**
     * Ustawienie na true daje mniejszy przycisk
     */
    small?: boolean;
    /**
     * Url przekazywany do buttona, decyduje czy będzie tag <a> czy <button>
     */
    url?: string;
    /**
     * jeśli zabraknie onClick i url, to button button będzie tylko divem
     */
    onClick?: () => void;
    className?: string;
    isLoading?: boolean;
}

enum EArrowPosition {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT'
}

const LinkButton: React.FC<ILinkButtonProps> = ({ children, arrowPosition, small, url, onClick, className, isLoading }) => {
    const classList = getClassList([
        'link-button',
        small ? 'link-button--small' : '',
        className ? className : '',
        isLoading && 'link-button--loading'
    ]);

    const linkContent: JSX.Element = (
        <>
            {arrowPosition === EArrowPosition.LEFT && (
                <div className="link-button__arrow link-button__arrow--left">←&nbsp;</div>
            )}
            <div className="link-button__text">
                {children}
            </div>
            {arrowPosition === EArrowPosition.RIGHT && (
                <div className="link-button__arrow link-button__arrow--right">&nbsp;→</div>
            )}
        </>
    );

    if (url) {
        return (
            <a className={classList} href={url} onClick={onClick}>
                {linkContent}
            </a>
        );
    }

    if (onClick) {
        return (
            <button
                type="button"
                className={classList}
                onClick={onClick}
                disabled={isLoading}
            >
                {linkContent}
                {isLoading && (
                    <div className="link-button__loading-icon">
                        <IconLoading />
                    </div>
                )}
            </button>
        )
    }

    return (
        <div className={classList}>
            {linkContent}
        </div>
    );
};

export { LinkButton, EArrowPosition };
