
enum EGoldenErrorCodes {
    VALIDATION = 'error.validation',
    UNAUTHORIZED = 'error.unauthorized',
    INFRASTRUCTURE = 'error.infrastructure'
}

class GoldenError extends Error {
    private readonly code: string;
    private readonly i18nKey: string;

    constructor(
        code: string,
        messageCode: string,
        i18nKey: string
    ){
        const message = `${ code } ${ messageCode }`;
        super(message);

        this.code = code;
        this.i18nKey = i18nKey;
    }

    public get errorCode(): string {
        return this.code;
    }

    public get translationKey(): string {
        return this.i18nKey;
    }
}

export { GoldenError, EGoldenErrorCodes }
