import './FormControls.scss';
import * as React from 'react';
import { MouseEvent } from 'react';
import { DefaultButton } from '../../../ui/Buttons/DefaultButton/DefaultButton';
import { useTranslation } from 'react-i18next';

interface IFormControls {
    onCancel: () => void,
    onAccept: (event: MouseEvent<HTMLButtonElement>) => void,
    isLoading?: boolean
}

const FormControls: React.FC<IFormControls> = ({ onCancel, onAccept, isLoading }) => {
    const { t } = useTranslation();
    return (
        <div className="form-controls">
            <DefaultButton
                className="form-controls__action-btn form-controls__action-btn--cancel"
                onClick={onCancel}
                hollow
            >
                {t('globals:cancel')}
            </DefaultButton>
            <DefaultButton
                className="form-controls__action-btn form-controls__action-btn--accept"
                onClick={onAccept}
                loading={isLoading}
                inForm
            >
                {t('globals:save')}
            </DefaultButton>
        </div>
    );
};

export { FormControls, IFormControls };
