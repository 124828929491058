import { SvgIcon } from './SvgIcon';
import * as React from 'react';

const IconLoading: React.FC = () => {
    return (
        <SvgIcon
            name="icon-loading"
            svgXml={`
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="3" r="2" fill="#6E768B"/>
                <circle opacity="0.4" cx="12" cy="21" r="2" fill="#6E768B"/>
                <circle opacity="0.7" cx="21" cy="12" r="2" transform="rotate(90 21 12)" fill="#6E768B"/>
                <circle opacity="0.2" cx="3" cy="12" r="2" transform="rotate(90 3 12)" fill="#6E768B"/>
                <circle opacity="0.8" cx="18.3643" cy="5.63604" r="2" transform="rotate(45 18.3643 5.63604)" fill="#6E768B"/>
                <circle opacity="0.25" cx="5.63574" cy="18.3641" r="2" transform="rotate(45 5.63574 18.3641)" fill="#6E768B"/>
                <circle opacity="0.5" cx="18.364" cy="18.3638" r="2" transform="rotate(135 18.364 18.3638)" fill="#6E768B"/>
                <circle opacity="0.1" cx="5.63642" cy="5.63623" r="2" transform="rotate(135 5.63642 5.63623)" fill="#6E768B"/>
                </svg>
            `}
        />
    );
};

export { IconLoading };
