import './GlobalApp.scss'
import * as React from 'react';
import { GlobalContextProvider } from './context/GlobalContext';
import { GlobalComponents } from './GlobalComponents';

const GlobalApp: React.FC = () => {
    return (
        <div className="global-app">
            <GlobalContextProvider>
                <GlobalComponents />
            </GlobalContextProvider>
        </div>
    );
};

export { GlobalApp };
