import { scrollDropDown } from './scrollDropDown';

const getNewDropDownSelection = {
    down: (selectedElementIndex: number, lastElementIndex: number, listClassName: string): number => {
        scrollDropDown('down', listClassName, selectedElementIndex, lastElementIndex);
        return selectedElementIndex === lastElementIndex ? 0 : selectedElementIndex + 1
    },
    up: (selectedElementIndex: number, lastElementIndex: number, listClassName: string): number => {
        scrollDropDown('up', listClassName, selectedElementIndex, lastElementIndex);
        return selectedElementIndex === 0 ? lastElementIndex : selectedElementIndex - 1
    }
};

export { getNewDropDownSelection }
