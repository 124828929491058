import { useEffect } from 'react';
import OverlayScrollbars from 'overlayscrollbars';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import './useStyledScrollbar.scss';

const useStyledScrollbar = ( cssSelector: string, options?: OverlayScrollbars.Options ): void => {

    useEffect(() => {
        const nodeList = document.querySelectorAll(cssSelector);
        if (nodeList) {
            OverlayScrollbars(nodeList, {
                className: 'os-theme-goldenline',
                ...options
            });
        }
    }, []);
};

export { useStyledScrollbar }
