
const scrollDropDown = (
    direction: string,
    listCssClass: string,
    selectedItemIndex: number,
    lastItemIndex: number
): void => {
    let scrollableContainer = document.querySelector(`${ listCssClass }`);
    if (scrollableContainer && scrollableContainer.classList.contains('dropdown-list--visible')) {
        // sprawd czy używamy OverlaidScrollbars, jeżeli tak, zmień scrollowany kontener
        const overlaidScrollbarContainer = document.querySelector(listCssClass + ' .os-viewport');
        if (overlaidScrollbarContainer) {
            scrollableContainer = overlaidScrollbarContainer
        }

        const dropdownListItems = scrollableContainer.querySelectorAll('.dropdown-list-item');
        const listLength = dropdownListItems.length;
        const isScrollable = listLength > 6;
        const itemHeight = dropdownListItems[0].clientHeight;
        const currentScrollTop = scrollableContainer.scrollTop;
        const newScrollTopForArrowUp = itemHeight * ( selectedItemIndex - 1.2 );
        const newScrollTopForArrowDown = itemHeight * ( selectedItemIndex - 5 );

        switch (direction) {
            case 'up':
                if (isScrollable && selectedItemIndex === 0) {
                    scrollableContainer.scrollTop = ( listLength - 1 ) * itemHeight;
                } else if (isScrollable && currentScrollTop > newScrollTopForArrowUp) {
                    scrollableContainer.scrollTop = newScrollTopForArrowUp;
                };
                break;
            case 'down':
                if (isScrollable && selectedItemIndex === lastItemIndex) {
                    scrollableContainer.scrollTop = 0;
                } else if (isScrollable && selectedItemIndex > 5 && currentScrollTop < newScrollTopForArrowDown) {
                    scrollableContainer.scrollTop = itemHeight * ( selectedItemIndex - 5 )
                }
        }
    }
};

export { scrollDropDown }
