import axios from 'axios';
import { TPill } from '../../components/ui/Pill/Pill';
import { getCsrfToken } from '../getCsrfToken/getCsrfToken';
import { getApiResponse } from '../apiUtils/getApiResponse';
import { GoldenError } from '../../class/GoldenError';
import {getApiUrl} from "../apiUtils/getApiUrl";

async function updateUserSkills(skillList: TPill[]): Promise<boolean> {
    try {
        const token = await getCsrfToken();
        const url = getApiUrl('/user-profile/skills/modify');
        const saveSkillsPost = await axios.post( url, {
            skills: skillList,
            csrfActionToken: token
        }, {
            withCredentials: true
        });

        const result = getApiResponse<boolean>(saveSkillsPost);

        if (result.isSuccess) {
            return true;
        } else {
            throw result.response;
        }
    } catch (error) {
        if (error.message === 'Network Error') {
            throw new GoldenError(
                'Network Error',
                '',
                'globals:errors:error.networkError'
            )
        }

        throw error;
    }
}

export { updateUserSkills }
