import './CloseButton.scss';
import * as React from 'react';
import { IconClear } from '../../../../../assets/icons/IconClear';

interface ICloseButton {
    onClick: () => void,
    className?: string
}

const CloseButton: React.FC<ICloseButton> = ({ onClick, className }) => {
    const classList = [
        'close-button',
        className ? className : ''
    ].join(' ');

    return (
        <button className={classList} onClick={onClick}>
            <div className="close-button__circle" />
            <IconClear />
        </button>
    );
};

export { CloseButton };
