import axios from 'axios';
import { Logger } from '../../class/Logger';
import { getApiUrl } from '../apiUtils/getApiUrl';

type TFirm = {
    Id: number,
    Name: string,
    State: string,
    EmployeesCount: number
}
async function getFirms(query: string): Promise<TFirm[]> {
    const logger = Logger.getInstance();

    try {
        const url = getApiUrl('/firma/autocompleter');
        const firms = await axios.get(url, {
            params: {
                query: query
            },
            withCredentials: true
        });

        const outFirms = [ ...firms.data[0], ...firms.data[1] ];

        return outFirms as TFirm[];
    } catch (e) {
        logger.logError('[ApiGetFirms]', e);
        throw e;
    }
}

export { getFirms, TFirm }
