
const getErrorMsgTranslationKey = (errorMsg: string): string => {
    const errors = 'globals:errors:';

    switch(errorMsg) {
        case 'Network Error':
            return errors + 'error.networkError';
        case 'Request failed with status code 500':
            return errors + 'error.infrastructure';
        default:
            return errors + 'error.generic'
    }
};

export { getErrorMsgTranslationKey }
