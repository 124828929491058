import { TFormConfig } from '../components/hooks/useSimpleForm';

function getFormDefaultState(formConfig: TFormConfig) {
    const defaultState = {};

    for (const fieldName in formConfig) {
        defaultState[`${ fieldName }`] = formConfig[fieldName].value;
        defaultState[`${ fieldName }Pass`] = false;
        defaultState[`${ fieldName }ErrorMsg`] = '';
    }

    return defaultState;
}

export { getFormDefaultState };
