import * as React from 'react';
import { useState, Dispatch, SetStateAction } from 'react';

import { AutocompleterSingleChoice } from '../../../ui/Form/AutocompleterSingleChoice/AutocompleterSingleChoice';
import { TDropDownListElement } from '../../../ui/DropDownList/DropDownList';
import { getFirms } from '../../../../api/getFirms/getFirms';
import { TSuggestionListUpdateFn } from '../../../ui/Form/ApiAutocompleter/ApiAutocompleter';
import { useTranslation } from 'react-i18next';

interface IFirmAutocompleter {
    onAccept: (newFirm: string) => void;
    onClear: () => void;
    errorMsg: string;
    updateErrorMsg: (newMsg: string) => void;
    className?: string;
}

const FirmAutocompleter: React.FC<IFirmAutocompleter> = ({ onAccept, onClear, errorMsg, updateErrorMsg, className }) => {
    const { t } = useTranslation();
    const [ firms, setFirmList ] = useState<string[]>([]);

    const getCompanyNames: TSuggestionListUpdateFn = async (
        query: string,
        updateAutocompleterListFn: Dispatch<SetStateAction<TDropDownListElement[]>>
    ) => {
        try {
            const firmSuggestions = await getFirms(query);
            setFirmList(firmSuggestions.map((firm) => firm.Name));

            const autocompleterList: TDropDownListElement[] = firmSuggestions.map((firm): TDropDownListElement => ({
                id: firm.Id,
                name: firm.Name
            }));

            updateAutocompleterListFn(autocompleterList);
        } catch (e) {
            updateErrorMsg(t('globals:errors:error.generic'));
        }
    };

    const handlers = {
        onAccept: (acceptedElement: TDropDownListElement): void => {
            updateErrorMsg('');
            const acceptedFirmName = acceptedElement.name;
            onAccept(acceptedFirmName);
        }
    };

    const isThatANewItem = (query: string) => {
        return !firms.some( (element) => element.toLowerCase() === query.toLowerCase());
    };

    return (
        <div className={className}>
            <AutocompleterSingleChoice
                onClearAcceptedSuggestion={onClear}
                name="firm-autocompleter"
                textInputData={{
                    placeholder: t('form:field:firm:placeholder'),
                    minimumLength: 3,
                    errorMsg: {
                        errorMsg: errorMsg,
                        updateErrorMsg: updateErrorMsg
                    }
                }}
                onSuggestionAccept={handlers.onAccept}
                getAutocompleterSuggestions={getCompanyNames}
                addCustomItemsData={{
                    label: t('editExperience:addNewFirm'),
                    isNewItem: isThatANewItem
                }}
            />
        </div>
    );
};

export { FirmAutocompleter, IFirmAutocompleter };
