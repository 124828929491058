import * as React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Select, TOptionValue, TSelectItem } from '../../../ui/Form/Select/Select';
import { getClassList } from '../../../../utils/getClassList';
import { useContext } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';

interface IYearSelect {
    name: string;
    onChange?: (newValue: TOptionValue) => void;
    className?: string;
    error?: boolean;
}

const YearSelect: React.FC<IYearSelect> = ({ name, onChange, className, error }) => {
    const { lang } = useContext(GlobalContext);
    moment.locale(lang);

    const { t } = useTranslation();

    const currentYear = moment().year();
    const years: TSelectItem[] = [];
    for (let year = currentYear - 100; year <= currentYear; year++) {
        years.push({
            label: year.toString(),
            value: year
        })
    }

    const classList = getClassList([
        'year-select',
        className && className
    ]);

    return (
        <Select
            name={name}
            placeholder={t('form:field:year:placeholder')}
            selectItems={years.reverse()}
            onChange={onChange}
            className={classList}
            error={error}
        />
    );
};

export { YearSelect, IYearSelect };
