import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { TSuggestionListUpdateFn } from '../../../ui/Form/ApiAutocompleter/ApiAutocompleter';
import { Dispatch } from 'react';
import { SetStateAction } from 'react';
import { TDropDownListElement } from '../../../ui/DropDownList/DropDownList';
import { AutocompleterSingleChoice } from '../../../ui/Form/AutocompleterSingleChoice/AutocompleterSingleChoice';
import { getPositions } from '../../../../api/getPositions/getPositions';

interface IPositionAutocompleter {
    onAccept: (newPosition: string) => void;
    onClear: () => void;
    errorMsg: string;
    updateErrorMsg: (newMsg: string) => void;
    className?: string;
}

const PositionAutocompleter: React.FC<IPositionAutocompleter> = ({ onAccept, onClear, errorMsg, updateErrorMsg, className }) => {
    const { t } = useTranslation();
    const [ positions, setPositions ] = useState<string[]>([]);

    const getSuggestedPositions: TSuggestionListUpdateFn = async (
        query: string,
        updateAutocompleterListFn: Dispatch<SetStateAction<TDropDownListElement[]>>
    ) => {
        try {
            const positionSuggestions = await getPositions(query);
            setPositions(positionSuggestions);

            const autocompleterList: TDropDownListElement[] = positionSuggestions.map((position): TDropDownListElement => ({
                id: position.toLowerCase(),
                name: position
            }));

            updateAutocompleterListFn(autocompleterList);
        } catch (e) {
            updateErrorMsg(t('globals:errors:error.generic'));
        }
    };

    const handlers = {
        onAccept: (acceptedElement: TDropDownListElement): void => {
            updateErrorMsg('');
            const newPosition = acceptedElement.name;
            onAccept(newPosition);
        }
    };

    const isThatANewItem = (query: string) => {
        return !positions.some((element) => element.toLowerCase() === query.toLowerCase());
    };

    return (
        <div className={className}>
            <AutocompleterSingleChoice
                onClearAcceptedSuggestion={onClear}
                name="position-autocompleter"
                textInputData={{
                    placeholder: t('form:field:position:placeholder'),
                    minimumLength: 3,
                    errorMsg: {
                        errorMsg: errorMsg,
                        updateErrorMsg: updateErrorMsg
                    }
                }}
                onSuggestionAccept={handlers.onAccept}
                getAutocompleterSuggestions={getSuggestedPositions}
                addCustomItemsData={{
                    label: t('editExperience:addNewPosition'),
                    isNewItem: isThatANewItem
                }}
            />
        </div>
    );
};

export { PositionAutocompleter, IPositionAutocompleter };
