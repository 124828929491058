import './DataProcessingInfo.scss';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const DataProcessingInfo: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="data-processing-info">
            <Trans i18nKey="userProfile:dataProcessingInfo:clause">
                Dane mogą być wykorzystane do:
                <a className="" href={t('userProfile:dataProcessingInfo:jobOffers')}>ofert pracy</a>
                publikowanych w serwisie;
                <a href={t('userProfile:dataProcessingInfo:contacts')}>potencjalnych znajomych</a>
                połączymy z pracodawcą, pokażemy wśród
                <a href={t('userProfile:dataProcessingInfo:employerProfiles')}>pracowników na jego profilu</a>.
                Aby zmienić swoje ustawienia prywatności określ
                <a href={t('userProfile:dataProcessingInfo:privacySettings')}>kto może oglądać Twój profil</a>.
            </Trans>
        </div>
    );
};

export { DataProcessingInfo };
