const EnvironmentType = {
    PROD: 'prod',
    TESTING: 'testing',
    DEV: 'dev',
    SSR: 'ssr'
};

interface IWindow extends Window {
    ENVIRONMENT?: string
}

function getEnvironment(): string {
    if (typeof window === 'object') {
        const ENVIRONMENT = (window as IWindow).ENVIRONMENT;
        return ENVIRONMENT as string;
    } else {
        return EnvironmentType.SSR;
    }
}

export { getEnvironment, EnvironmentType };
