import { MutableRefObject, useEffect, useState } from 'react';

interface IUseKeyboardFocus {
    focused: boolean;
}

const useKeyboardFocus = (refs: MutableRefObject<HTMLElement | null>[]): IUseKeyboardFocus => {
    const [ focused, setFocused ] = useState(false);
    const [ usedMouse, setUsedMouse ] = useState(false);

    const handlers = {
        focus: () => {
            if (!usedMouse) {
                setFocused(true);
            }
        },
        mousedown: () => {
            if (!usedMouse) {
                setUsedMouse(true);
            }
        },
        blur: () => {
            if (focused) {
                setFocused(false);
            }
        }
    };

    useEffect(() => {
        for (const ref of refs) {
            if(ref.current) {
                ref.current.addEventListener('focus', handlers.focus);
                ref.current.addEventListener('mousedown', handlers.mousedown);
                ref.current.addEventListener('blur', handlers.blur);
            }
        }
        return () => {
            for (const ref of refs) {
                if(ref.current) {
                    ref.current.removeEventListener('focus', handlers.focus);
                    ref.current.removeEventListener('mousedown', handlers.mousedown);
                    ref.current.removeEventListener('blur', handlers.blur);
                }
            }
        }
    }, [ usedMouse, focused ]);

    return {
        focused: focused
    }
};

export { useKeyboardFocus }
