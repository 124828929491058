import './Select.scss';
import * as React from 'react';
import {ChangeEvent, MutableRefObject, useRef, useState} from 'react';

import { DropDownList, TDropDownListElement } from '../../DropDownList/DropDownList';
import { getClassList } from '../../../../utils/getClassList';
import { IconChevron } from '../../../../../assets/icons/IconChevron';
import { useCustomElementBlur } from '../../../hooks/useCustomElementBlur';
import { dropDownKeyDownActions, TDropDownKeyDownActions } from '../../DropDownList/dropDownKeyDownActions';
import { getNewDropDownSelection } from '../../DropDownList/getNewDropDownSelection';

/**
 * TODO Dodać eventy na wyszukiwanie
 * TODO Dodać hovery, focus, i zmianę strzałki przy akcjach
 * TODO Ogarnąc zachowanie initial value
 */

interface ISelect {
    /**
     * Name jest też przekazane aby dodac klase css do dropdown-list
     */
    name: string;
    placeholder: string;
    selectItems: TSelectItem[];
    onChange?: (newValue: TOptionValue) => void;
    initialValue?: TOptionValue;
    className?: string;
    error?: boolean;
}

type TSelectItem = {
    label: string,
    value: TOptionValue
};

type TOptionValue = string | number

const Select: React.FC<ISelect> = ({ name, placeholder, selectItems, onChange, initialValue, className, error }) => {
    const [ listOpened, setListOpened ] = useState(false);
    const [ selectedElement, selectElement ] = useState(0);
    const [ chosenElement, chooseElement ] = useState<number>(-1);
    const selectButton: MutableRefObject<HTMLDivElement | null> = useRef(null);
    const selectWrapper: MutableRefObject<HTMLDivElement | null> = useRef(null);

    const selectOpened = () => {
        if(selectWrapper.current) {
            return selectWrapper.current.classList.contains('select--opened');
        }
        return false
    };

    const hideList = () => {
        setListOpened(false);
    };
    useCustomElementBlur(
        `select--${ name }`,
        [ `select--${ name }` ],
        selectOpened,
        hideList
    );

    const chosenItem = selectItems[chosenElement];

    const dropDownListItems = selectItems.map((selectItem): TDropDownListElement => ({
        name: selectItem.label,
        id: selectItem.value
    }));

    const lastElementIndex = dropDownListItems.length;

    const selectionActions: TDropDownKeyDownActions = {
        down: (): void => {
            selectElement(
                getNewDropDownSelection.down(selectedElement, lastElementIndex, `.${ name }__dropdown-list`)
            )
        },
        up: (): void => {
            selectElement(
                getNewDropDownSelection.up(selectedElement, lastElementIndex, `.${ name }__dropdown-list`)
            )
        },
        accept: (): void => {
            chooseElement(selectedElement);
            setListOpened(false);

            if (onChange) {
                const newValue = selectItems[selectedElement].value;
                onChange(newValue);
            }
        }
    };

    const handlers = {
        onButtonClick: (event: React.MouseEvent): void => {
            event.preventDefault();
            setListOpened(!listOpened);
        },
        onItemSelect: (index: number): void => {
            selectElement(index);
        },
        onItemClick: (): void => {
            chooseElement(selectedElement);
            setListOpened(false);

            if (onChange) {
                const newValue = selectItems[selectedElement].value;
                onChange(newValue);
            }
        },
        onKeyDown: (event: React.KeyboardEvent<HTMLElement>): void => {
            dropDownKeyDownActions(event, selectionActions)
        },
        onSelectChange: (event: ChangeEvent<HTMLSelectElement>) => {
            let chosenIndex = -1;
            selectItems.some((item, index) => {
                if (item.value === event.currentTarget.value) {
                    chosenIndex = index;
                    return true;
                } else {
                    return false;
                }
            });
            chooseElement(chosenIndex)
        },
        onSelectFocus: (): void => {
            setListOpened(true);
        }
    };

    const selectClassList = getClassList([
        'select',
        `select--${ name }`,
        chosenElement > -1 && 'select--value-chosen',
        listOpened ? 'select--opened' : 'select--closed',
        error && error === true && 'select--error',
        className && className
    ]);

    const selectTriggerClassList = getClassList([
        'select__trigger',
        listOpened ? 'select__trigger--opened' : ''
    ])

    return (
        <div className={selectClassList} ref={selectWrapper}>
            <div className="select__hidden-input">
                <select
                    name={name}
                    id={name}
                    onChange={handlers.onSelectChange}
                    value={chosenElement === -1 ? placeholder : chosenItem.value}
                    tabIndex={-1}
                >
                    <option value="" hidden>{placeholder}</option>
                    {selectItems.map((selectItem, index) => {
                        return (
                            <option
                                key={`${ index }${ selectItem.value }`}
                                value={selectItem.value}
                                hidden
                            >
                                {selectItem.label}
                            </option>
                        )
                    })}
                </select>
            </div>
            <div
                className={selectTriggerClassList}
                onMouseDown={handlers.onButtonClick}
                onFocus={handlers.onSelectFocus}
                tabIndex={0}
                ref={selectButton}
                onKeyDown={handlers.onKeyDown}
            >
                <span className="select__label">
                    {chosenElement === -1 ? placeholder : chosenItem.label}
                </span>
                <div className="select__arrow">
                    <div className="select__arrow-icon">
                        <IconChevron />
                    </div>
                </div>
            </div>
            <DropDownList
                name={`${ name }-list`}
                selectedElement={selectedElement}
                selectElement={handlers.onItemSelect}
                elementClick={handlers.onItemClick}
                list={dropDownListItems}
                className={`select__dropdown-list ${ name }__dropdown-list`}
                visible={listOpened}
            />
        </div>
    );
};

export { Select, ISelect, TOptionValue, TSelectItem };
