import './OnboardingHeader.scss';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LinkButton } from '../../../ui/Buttons/LinkButton/LinkButton';

interface IStepHeader {
    currentStep: number;
    stepsAmount: number;
    skip: () => void;
}

const OnboardingHeader: React.FC<IStepHeader> = ({ currentStep, stepsAmount, skip }) => {
    const { t } = useTranslation();

    const step = `${ t('onBoarding:step') } ${ currentStep }`;
    const ofSteps = `${ t('onBoarding:of') } ${ stepsAmount }`;
    const barStyle = {
        width: `${ currentStep / stepsAmount * 100 }%`
    };

    return (
        <header className="step-header">
            <div className="step-header__top-wrap">
                <div className="step-header__step">
                    <span className="step-header__current-step">{step}</span> <span className="step-header__of-steps">{ofSteps}</span>
                </div>
                <div className="step-header__skip" >
                    <LinkButton onClick={skip}>{t('onBoarding:skip')}</LinkButton>
                </div>
            </div>

            <div className="step-header__progress-bar">
                <div className="step-header__progress-bar-fill" style={barStyle} />
            </div>
        </header>
    );
};

export { OnboardingHeader, IStepHeader };
