import * as React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Select, TOptionValue, TSelectItem } from '../../../ui/Form/Select/Select';
import { getClassList } from '../../../../utils/getClassList';
import { useContext } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';

interface IMonthSelect {
    name: string;
    onChange?: (newValue: TOptionValue) => void;
    className?: string;
    error?: boolean;
}

const MonthSelect: React.FC<IMonthSelect> = ({ name, onChange, className, error }) => {
    const { lang } = useContext(GlobalContext);
    const { t } = useTranslation();

    moment.locale(lang);

    const months: TSelectItem[] = moment.months('MMMM').map((month, index): TSelectItem => ({
        label: month.charAt(0).toUpperCase() + month.slice(1), //TODO To jest "dej z dużej litery", wynosimy?
        value: moment().month(index).format('MM')
    }));

    const classList = getClassList([
        'month-select',
        className && className
    ]);

    return (
        <Select
            name={name}
            placeholder={t('form:field:month:placeholder')}
            selectItems={months.reverse()}
            onChange={onChange}
            className={classList}
            error={error}
        />
    );
};

export { MonthSelect, IMonthSelect };
