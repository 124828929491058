import axios from 'axios';
import { sendErrorToLogger } from '../../utils/sendErrorToLogger';
import {getApiUrl} from "../apiUtils/getApiUrl";

type TUserSkill = {
    name: string,
    id: number | string | null,
};

const getUserSkills = async (
    query: string,
): Promise<TUserSkill[]> => {
    try {
        const url = getApiUrl('/skills/autocomplete/');
        const response = await axios.get(url, {
            params: {
                name: query
            },
            withCredentials: true
        });

        const mappedData = response.data.map( (element: {}): TUserSkill => {
            return {
                name: element['Name'],
                id: element['Id']
            };
        });

        return mappedData;
    } catch (error) {
        sendErrorToLogger(error);
        throw error;
    }
};

export { getUserSkills, TUserSkill }
