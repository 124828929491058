import './OnboardingStepFooter.scss';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MouseEvent } from 'react';

import { DefaultButton } from '../../../ui/Buttons/DefaultButton/DefaultButton';
import { DataProcessingInfo } from '../../DataProcessingInfo/DataProcessingInfo';

interface IOnboardingStepFooter {
    onClickNext: (event: MouseEvent<HTMLButtonElement>) => void;
    onClickPrev?: (event: MouseEvent<HTMLButtonElement>) => void;
    isLoading?: boolean;
    lastStep?: boolean
}

const OnboardingStepFooter: React.FC<IOnboardingStepFooter> = ({ onClickNext, onClickPrev, isLoading, lastStep }) => {
    const { t } = useTranslation();

    return (
        <div className="onboarding-step-footer">
            <DataProcessingInfo />
            <nav className={`onboarding-step-footer__nav ${ !onClickPrev && 'onboarding-step-footer__nav--forward-only' }`}>
                <DefaultButton
                    onClick={onClickNext}
                    className="onboarding-step-footer__nav-button"
                    loading={isLoading}
                    inForm
                >
                    {lastStep ? t('onBoarding:save') : t('onBoarding:next') }
                </DefaultButton>

                {onClickPrev && (
                    <DefaultButton
                        onClick={onClickPrev}
                        hollow
                        className="onboarding-step-footer__nav-button"
                    >
                        {t('onBoarding:prev')}
                    </DefaultButton>
                )}
            </nav>
        </div>
    );
};

export { OnboardingStepFooter, IOnboardingStepFooter };
