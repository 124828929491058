import './UserSkillsForm.scss'
import * as React from 'react';
import { FormEvent, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiAutocompleter } from '../../../ui/Form/ApiAutocompleter/ApiAutocompleter';
import { TUserSkill } from '../../../../api/getUserSkills/getUserSkills';
import { PillsList } from '../../../ui/PillsList/PillsList';
import { useSkillsList } from '../../../hooks/useSkillsList';
import { FormControls } from '../../forms/FormControls/FormControls';
import { TControlsRender } from '../../forms/UserExperienceForm/UserExperienceForm';
import { FlashMessage } from '../../../ui/FlashMessage/FlashMessage';
import { getFormErrorMsg } from '../formUtils/getFormErrorMsg';
import { GoldenError } from '../../../../class/GoldenError';
import { TUpdateFieldFn, updateFieldErrors } from '../formUtils/updateFieldErrors';

interface INewUserSkills {
    skills: TUserSkill[];
    controlsRender?: TControlsRender;
    afterSave?: () => Promise<void>;
    afterCancel?: () => void;
}

const UserSkillsForm: React.FC<INewUserSkills> = ({ skills, controlsRender, afterCancel, afterSave }) => {
    const { t } = useTranslation();
    const {
        skillsList,
        listEditActions,
        saveSkills,
        skillsErrorMsg,
        isSaving,
        getAutocompletedSkills
    } = useSkillsList(skills);

    const isThatANewItem = (query: string) => {
        return !skills.some( element => {
            return element.name.toLowerCase() === query.toLowerCase()
        })
    };

    const handlers = {
        onSubmit: async (event: FormEvent): Promise<void> => {
            event.preventDefault();

            if (skillsList.length === 0) {
                skillsErrorMsg.updateErrorMsg(t('onBoarding:errors:noSkillsAdded'));
            } else {
                try {
                    await saveSkills(afterSave);
                } catch (error) {
                    const { message, failedFields } = getFormErrorMsg(error as GoldenError, t);
                    error.updateErrorMsg(message);

                    const msgUpdate: TUpdateFieldFn = (fieldName: string, fieldMsg: string) => {
                        // Ignorujemy fieldName bo mamy tylko jedno pole
                        error.updateErrorMsg(fieldMsg);
                    };
                    updateFieldErrors([ 'skills' ], failedFields, msgUpdate);
                }
            }
        },
        onCancel: () => {
            //TODO: Zrobic dla profilu
            if (afterCancel) {
                afterCancel()
            }
        },
        onAccept: (event: MouseEvent<HTMLButtonElement>) => {
            //TODO: Zrobic dla profilu
        }
    };

    const ControlsRender: React.FC = controlsRender ?
        controlsRender(handlers.onAccept, handlers.onCancel, isSaving)
        : () => (
            <FormControls
                onAccept={handlers.onAccept}
                onCancel={handlers.onCancel}
                isLoading={isSaving}
            />
        );

    return (
        <div className="user-skills">
            {listEditActions && (
                <form onSubmit={handlers.onSubmit}>
                    <div className="user-skills__editor">
                        <ApiAutocompleter
                            name="user-skills"
                            textInputData={{
                                placeholder: t('userProfile:forms:skillInputPlaceholder'),
                                minimumLength: 2,
                                errorMsg: skillsErrorMsg
                            }}
                            onSuggestionAccept={listEditActions.addItem}
                            getAutocompleterSuggestions={getAutocompletedSkills}
                            addCustomItemsData={{
                                label: t('editSkills:addNewSkill'),
                                isNewItem: isThatANewItem
                            }}
                        />
                        <PillsList
                            pills={skillsList}
                            onPillRemove={listEditActions.removeItem}
                        />
                    </div>
                    {skillsErrorMsg.errorMsg &&
                        <FlashMessage type="error" message={skillsErrorMsg.errorMsg} />
                    }
                    <div className="user-experience-form__form-controls">
                        <ControlsRender />
                    </div>
                </form>
            )}
            {!listEditActions && // TODO Może lepiej oddzielny komponent ?
                <PillsList pills={skillsList} />
            }
        </div>
    );
};

export { UserSkillsForm };
