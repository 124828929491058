import axios from 'axios';
import { Logger } from '../../class/Logger';
import {getApiUrl} from "../apiUtils/getApiUrl";

type TPosition = string

async function getPositions(query: string): Promise<TPosition[]> {
    const logger = Logger.getInstance();

    try {
        const url = getApiUrl('/autocompleter/positions');
        const positions = await axios.get(url, {
            params: {
                query: query
            },
            withCredentials: true
        });

        const outPositions = positions.data.results;

        return outPositions;
    } catch (e) {
        logger.logError('[ApiGetPositions]', e);
        throw e;
    }
}

export { getPositions, TPosition }
