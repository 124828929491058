import * as React from 'react';
import { render } from 'react-dom';
//import {GlobalComponents, IGlobalComponents} from '../components/GlobalComponents';
import { Logger } from '../class/Logger';
import { initI18n } from '../translations/i18next';
import { IWindow } from '../typings/IWindow';
//import { getOnboardingCheck } from '../api/getOnboardingCheck';
import { GlobalApp } from '../components/GlobalApp';

declare const window: IWindow & Window;

(function(): void {
    document.addEventListener('DOMContentLoaded', async function() {
        const domContainer = document.getElementById('react-global-components');
        if (domContainer !== null) {
            const lang = window.LOCALE ? window.LOCALE : 'pl';
            initI18n(lang);

            render(React.createElement(GlobalApp), domContainer);
        } else {
            const logger = Logger.getInstance();
            logger.logError('twigContainer not available!');
        }
    });
})();
