import { getEnvironment, EnvironmentType } from './environment';
import {IWindow} from '../typings/IWindow';

declare const window: IWindow;

function pushGtmEvent(category: string, action: string, label: string, value: string | number | boolean, nonInteractive: boolean): void {
    const gtmEvent = {
        event: 'gtmEvent',
        gtmCategory: category,
        gtmAction: action,
        gtmLabel: label,
        gtmValue: value,
        gtmNonInteraction: nonInteractive
    };

    const isDataLayerAvailable = window && window.dataLayer;

    if (getEnvironment() === EnvironmentType.DEV || getEnvironment() === EnvironmentType.SSR) {
        console.table([ gtmEvent ]);
    } else if (isDataLayerAvailable) {
        window.dataLayer.push(gtmEvent);
    } else {
        console.error('DataLayer not available!'); //TODO Sentry?
    }
}

export { pushGtmEvent };
