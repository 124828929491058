import './AutocompleterInput.scss';
import * as React from 'react';
import { ChangeEvent, useState } from 'react';
import { IconLoading } from '../../../../../../assets/icons/IconLoading';
import { getClassList } from '../../../../../utils/getClassList';
import {dropDownKeyDownActions, TDropDownKeyDownActions} from '../../../DropDownList/dropDownKeyDownActions';

interface IAutocompleterInput {
    name: string;
    value: string | number;
    actions: TAutocompleterInputActions;
    selection: TDropDownKeyDownActions;
    minimumLength: number;
    errorMsg?: string;
    disabled?: boolean;
    placeholder?: string | number;
    isLoading?: boolean;
}

type TAutocompleterInputActions = {
    onChange: (newValue: string) => void,
    onFocus: () => void,
}

const AutocompleterInput: React.FC<IAutocompleterInput> = ({ name, value, actions, selection, errorMsg, disabled, placeholder, isLoading }) => {
    const defaultInputStatus = disabled ? 'disabled' : 'idle';
    const [ inputStatus, setInputStatus ] = useState(defaultInputStatus);

    const classList = getClassList([
        'autocompleter-text-field',
        inputStatus === 'focus' ? 'autocompleter-text-field--focus' : '',
        disabled ? 'autocompleter-text-field--disabled' : '',
        errorMsg ? 'autocompleter-text-field--error' : ''
    ]);

    const inputClassList = getClassList([
        'autocompleter-text-field__input',
        name ? `autocompleter-text-field__input--${name}` : ''
    ]);

    const handlers = {
        onFocus: (): void => {
            setInputStatus('focus');
            actions.onFocus && actions.onFocus();
        },
        onChange: (event: ChangeEvent<HTMLInputElement>): void => {
            actions.onChange(event.target.value);
        },
        onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>): void => {
            dropDownKeyDownActions(event, selection)
        }
    };

    return (
        <div className={classList}>
            <div className="autocompleter-text-field__input-wrapper">
                <input
                    name={name}
                    id={name}
                    className={inputClassList}
                    type="text"
                    value={value}
                    onChange={handlers.onChange}
                    onFocus={handlers.onFocus}
                    onKeyDown={handlers.onKeyDown}
                    placeholder={`${ placeholder ? placeholder : '' }`}
                    autoComplete="off"
                />
                {isLoading && <div className="autocompleter-text-field__loader">
                    <IconLoading />
                </div>}
            </div>
            {errorMsg && (
                <span className="autocompleter-text-field__error-msg">
                    {errorMsg}
                </span>
            )}
        </div>
    );
};

export { AutocompleterInput, IAutocompleterInput, TAutocompleterInputActions };
