import './CheckboxField.scss';
import * as React from 'react';
import { IconCheck } from '../../../../../assets/icons/IconCheck';
import { MutableRefObject, useRef } from 'react';
import { getClassList } from '../../../../utils/getClassList';
import { useKeyboardFocus } from '../../../hooks/useKeyboardFocus';

interface ICheckboxField {
    name: string,
    checked: boolean,
    value: string,
    actions: TCheckboxActions,
    errorMsg?: string,
    label?: string | JSX.Element,
    description?: string,
    disabled?: boolean
}

type TCheckboxActions = {
    onClick?: () => void;
    onChangeEvent?: (event: React.ChangeEvent) => void;
}

const CheckboxField: React.FC<ICheckboxField> = ({ name, checked, value, actions, errorMsg, label, description, disabled }) => {
    const checkboxLabel: MutableRefObject<HTMLLabelElement | null> = useRef(null);
    const checkboxInput: MutableRefObject<HTMLInputElement | null> = useRef(null);
    const { focused } = useKeyboardFocus([ checkboxLabel, checkboxInput ]);

    const classList = getClassList([
        'checkbox-field',
        checked ? 'checkbox-field--off' : 'checkbox-field--on',
        disabled ? 'checkbox-field--disabled' : '',
        errorMsg ? 'checkbox-field--error' : '',
        focused ? 'checkbox-field--focused' : ''
    ]);

    return (
        <>
            <div className={classList}>
                <div className="checkbox-field__input-wrap">
                    <div className="checkbox-field__checkbox-wrap">
                        <input
                            id={name}
                            name={name}
                            value={value}
                            className="checkbox-field__hidden-input"
                            type="checkbox"
                            disabled={disabled}
                            onClick={actions.onClick}
                            onChange={actions.onChangeEvent}
                            ref={checkboxInput}
                        />
                        <div className="checkbox-field__checkbox-input" />
                        <div className="checkbox-field__checkbox-focused" />
                        {checked && (
                            <div className="checkbox-field__checkbox-icon">
                                <IconCheck />
                            </div>
                        )}
                    </div>
                </div>
                <label
                    className="checkbox-field__label"
                    htmlFor={name}
                    ref={checkboxLabel}
                >
                    {label && (
                        <span className="checkbox-field__title">{label}</span>
                    )}
                    {description && (
                        <span className="checkbox-field__description">{description}</span>
                    )}
                </label>
            </div>
            {errorMsg && (
                <span className="checkbox-field__error-msg">
                    {errorMsg}
                </span>
            )}
        </>
    );
};

export { CheckboxField, TCheckboxActions };
