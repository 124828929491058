import * as React from 'react';
import { OnboardingModal } from './domain/OnboardingModal/OnboardingModal';
import { useContext } from 'react';
import { GlobalContext } from './context/GlobalContext';

const GlobalComponents: React.FC = () => {
    const { isOnboardingFinished } = useContext(GlobalContext);

    return (
        <div className="global-components">
            <OnboardingModal isOnboardingFinished={isOnboardingFinished} />
        </div>
    );
};

export { GlobalComponents };
