import './PillsList.scss';
import * as React from 'react';
import { Pill, TPill } from '../Pill/Pill';

interface IPillsList {
    pills: TPill[];
    /**
     *  Funkcja do usunięcia pilla, jeśli nie jest przekazana,
     *  komponent traktujemy jako czysto displayowy
     */
    onPillRemove?: (index: number) => void;
    fullWidth?: boolean;
}

const PillsList: React.FC<IPillsList> = ({ pills, onPillRemove, fullWidth } ) => {
    const listItemClassList = [
        'pills-list__item',
        fullWidth ? 'pills-list__item--full-width' : ''
    ].join(' ');

    return (
        <ul className="pills-list">
            { pills.map( (pill, index) => {
                const removeHandler = (): void => {
                    if(onPillRemove) onPillRemove(index)
                };

                return (
                    <li key={pill.name.toLowerCase()} className={listItemClassList}>

                        { onPillRemove ?
                            <Pill fullWidth={fullWidth} onPillRemove={removeHandler} >{pill.name}</Pill>
                            :
                            <Pill fullWidth={fullWidth} >{pill.name}</Pill>
                        }
                    </li>
                )
            }) }
        </ul>
    );
};

export { PillsList, TPill };
