import { TUserLocation } from './getUserLocationSuggestions/getUserLocationSuggestions';
import axios from 'axios';

import { getCsrfToken } from './getCsrfToken/getCsrfToken';
import { GoldenError } from '../class/GoldenError';
import { getApiResponse } from './apiUtils/getApiResponse';
import { getApiUrl } from './apiUtils/getApiUrl';

type TUserLocationToPost = {
    locationId: string | number,
    isNewLocation: boolean
};

const updateUserLocation = async (selectedLocation: TUserLocation ): Promise<string> => {
    const mappedLocation: TUserLocationToPost = {
        locationId: selectedLocation.id,
        isNewLocation: selectedLocation.isNew
    };

    try {
        const token = await getCsrfToken();
        const url = getApiUrl('/user-profile/location/modify');
        const response = await axios.post(url,
            {
                location: mappedLocation,
                csrfActionToken: token
            }, {
                withCredentials: true
            });

        const result = getApiResponse<string>(response);

        if (result.isSuccess) {
            return 'success'
        } else {
            throw result.response;
        }
    } catch (error) {
        if (error.message === 'Network Error') {
            throw new GoldenError(
                'Network Error',
                '',
                'globals:errors:error.networkError'
            )
        }

        throw error;

        // TODO: Logika dla sentry ?
        // const logger = Logger.getInstance();
        //
        // const errorsIgnoredByLogger = [
        //     EGoldenErrorCodes.UNAUTHORIZED
        // ];
        //
        // const shouldBeIgnored = errorsIgnoredByLogger.find((errorCode: string) => {
        //     return errorCode === (error as GoldenError).errorCode
        // });
        //
        // if (!shouldBeIgnored) {
        //     logger.logError('[User location] Failed to update user location: ', error);
        // }
    }
};

export { updateUserLocation, TUserLocationToPost }
