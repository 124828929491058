import './OnboardingModal.scss';
import * as React from 'react';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GlModal } from '../../layout/GlModal/GlModal';
import { OnboardingStep } from './OnboardingStep/OnboardingStep';
import { OnboardingHeader } from './OnboardingHeader/OnboardingHeader';
import { TControlsRender } from '../forms/TControlsRender';
import { OnboardingStepFooter } from './OnboardingStepFooter/OnboardingStepFooter';
import { UserExperienceForm } from '../forms/UserExperienceForm/UserExperienceForm';
import { UserLocationForm } from '../forms/UserLocationForm/UserLocationForm';
import { UserSkillsForm } from '../forms/UserSkillsForm/UserSkillsForm';
import { saveOnboardingFinish } from '../../../api/saveOnboardingFinish';
import { pushSiteExitEvent, pushStepSaveEvent, pushStepSkipEvent } from './gtmEvents';

interface IOnboardingModal {
    isOnboardingFinished: boolean | null;
}

const OnboardingModal: React.FC<IOnboardingModal> = ({ isOnboardingFinished }) => {
    const { t } = useTranslation();
    const [ currentStep, updateCurrentStep ] = useState(1);
    const [ shouldShowOnboarding, updateShouldShowOnboarding ] = useState(false);
    const currentStepContainer: MutableRefObject<HTMLElement | null> = useRef(null);

    const quitOnboarding = () => {
        if (shouldShowOnboarding) {
            pushSiteExitEvent(currentStep);
        }
    };

    useEffect(() => {
        if (isOnboardingFinished === false) {
            updateShouldShowOnboarding(true);
        }
    }, [ isOnboardingFinished ]);

    useEffect(() => {
        currentStepContainer.current = document.querySelector('.onboarding-step--visible');

        if(currentStepContainer.current) {
            currentStepContainer.current.focus();
        }
    }, [ currentStep ]);

    useEffect(() => {
        if (shouldShowOnboarding) {
            window.addEventListener('beforeunload', quitOnboarding);
        }
    }, [ shouldShowOnboarding ]);

    const maxStep = 3;
    const isLastStep = currentStep === maxStep;

    const actions = {
        goToNextPage: (): void => {
            updateCurrentStep(currentStep + 1);
        },
        //  goToPrevPage: (): void => updateCurrentStep(currentStep - 1), TODO: Ustalić czy będziemy wracać do opcjia z "wstecz" i ew wywalić
        onOnboardingFinished: async (): Promise<void> => {
            updateShouldShowOnboarding(false);
            await saveOnboardingFinish();
        }
    };

    const handlers = {
        afterStepSave: (): void => {
            pushStepSaveEvent(currentStep);
            actions.goToNextPage();
        },
        afterLastStepSave: async (): Promise<void> => {
            pushStepSaveEvent(currentStep);
            await actions.onOnboardingFinished();
        },
        skipPage: (): void => {
            pushStepSkipEvent(currentStep);

            if(isLastStep) {
                actions.onOnboardingFinished();
            } else {
                actions.goToNextPage();
            }
        }
    };

    const StepControls: TControlsRender = (onAccept, onCancel, isLoading): React.FC => () => {
        return (
            <OnboardingStepFooter
                onClickNext={onAccept}
                lastStep={isLastStep}
                isLoading={isLoading}
            />
        )
    };

    return (
        <GlModal
            className="onboarding-modal"
            visible={shouldShowOnboarding}
            innerScrolls={[
                'dropdown-list'
            ]}
        >
            <OnboardingHeader
                currentStep={currentStep}
                stepsAmount={3}
                skip={handlers.skipPage}
            />
            <div className="onboarding-modal__steps-wrapper">
                <OnboardingStep
                    headline={t('onBoarding:step1:headline')}
                    stepNumber={1}
                    isActive={shouldShowOnboarding && currentStep === 1}
                >
                    <UserLocationForm
                        controlsRender={StepControls}
                        afterSave={handlers.afterStepSave}
                    />
                </OnboardingStep>
                <OnboardingStep
                    headline={t('onBoarding:step2Headline')}
                    stepNumber={2}
                    isActive={shouldShowOnboarding && currentStep === 2}
                >
                    <UserExperienceForm
                        controlsRender={StepControls}
                        afterSave={handlers.afterStepSave}
                    />
                </OnboardingStep>
                <OnboardingStep
                    headline={t('onBoarding:step3Headline')}
                    stepNumber={3}
                    isActive={shouldShowOnboarding && currentStep === 3}
                >
                    <UserSkillsForm
                        controlsRender={StepControls}
                        afterSave={handlers.afterLastStepSave}
                        skills={[]}
                    />
                </OnboardingStep>
            </div>
        </GlModal>
    );
};

export { OnboardingModal, IOnboardingModal }

