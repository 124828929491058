import { TGoldenAppError, TValidationInfo } from './getApiResponse';
import { EGoldenErrorCodes, GoldenError } from '../../class/GoldenError';
import { ValidationError } from '../../class/ValidationError';

function getResultError(goldenAppError: TGoldenAppError): GoldenError {
    const { code, messageCode } = goldenAppError.error;

    switch (code) {
        case EGoldenErrorCodes.UNAUTHORIZED:
            return new GoldenError(
                EGoldenErrorCodes.UNAUTHORIZED,
                messageCode,
                'onBoarding:errors:unauthorizedOnSave',
            );
        case EGoldenErrorCodes.VALIDATION:
            return new ValidationError(
                EGoldenErrorCodes.VALIDATION,
                messageCode,
                'onBoarding:errors:validationOnSave',
                (goldenAppError.error as TValidationInfo).formErrors
            );
        case EGoldenErrorCodes.INFRASTRUCTURE:
            return new GoldenError(
                EGoldenErrorCodes.INFRASTRUCTURE,
                messageCode,
                'globals:errors:error.infrastructure',
            );
        default:
            return new GoldenError(
                code,
                messageCode,
                'globals:errors:error.generic',
            );
    }
}

export { getResultError }
