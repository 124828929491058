import * as React from 'react';
import { SvgIcon } from './SvgIcon';

const IconCheck = () => {
    return (
        <SvgIcon
            name="check"
            svgXml={`
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="IconCheckPath" fill-rule="evenodd" clip-rule="evenodd" d="M18.4065 6.22116C18.8367 6.56754 18.9046 7.19705 18.5582 7.62721L10.8858 16.8026L6.6093 13.2826C6.18983 12.9233 6.14101 12.292 6.50027 11.8726C6.85952 11.4531 7.4908 11.4043 7.91027 11.7635L10.621 13.9426L17.0005 6.37286C17.3468 5.9427 17.9763 5.87478 18.4065 6.22116Z" fill="white"/>
                </svg>
            `}
        />
    );
};

export { IconCheck };
