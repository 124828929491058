import axios from 'axios';
import { GoldenError, EGoldenErrorCodes } from '../../class/GoldenError';
import { Logger } from '../../class/Logger';
import { getApiResponse, TGoldenAppError, TGoldenAppResult } from '../apiUtils/getApiResponse';
import {getApiUrl} from "../apiUtils/getApiUrl";

const logger = Logger.getInstance();

const getCsrfToken = async (): Promise<string> => {
    const url = getApiUrl('/89aab13233e28fff454eb08f375a711b');

    try {
        const response = await axios.get(url, { withCredentials: true });

        const result = getApiResponse<string>(response);

        if (result.isSuccess) {
            return (result.response as TGoldenAppResult<string>).result.data;
        } else {
            const { code, messageCode } = (result.response as TGoldenAppError).error;

            switch ((result.response as TGoldenAppError).error.code) {
                case EGoldenErrorCodes.INFRASTRUCTURE:
                    throw new GoldenError(
                        EGoldenErrorCodes.INFRASTRUCTURE,
                        messageCode,
                        'globals:errors:error.infrastructure',
                    );
                default:
                    throw new GoldenError(
                        code,
                        messageCode,
                        'globals:errors:error.generic',
                    );
            }
        }
    }
    catch (error) {
        if (error.message === 'Network Error') {
            throw new GoldenError(
                'Network Error',
                '',
                'globals:errors:error.networkError'
            )
        }

        logger.logError('[Get Token]: ', error);
        throw error;
    }
};

export { getCsrfToken }
