import axios from 'axios';

import { getApiResponse } from './apiUtils/getApiResponse';
import { Logger } from '../class/Logger';
import { getCsrfToken } from './getCsrfToken/getCsrfToken';
import { getApiUrl } from './apiUtils/getApiUrl';

async function saveOnboardingFinish(): Promise<boolean> {
    try {
        const url = getApiUrl('/user-profile/onboarding/finished');
        const onboardingFinishPost = await axios.post(url, {
            onboardingFinished: true,
            csrfActionToken: await getCsrfToken()
        }, {
            withCredentials: true
        });
        const result = getApiResponse<boolean>(onboardingFinishPost);

        if (result.isSuccess) {
            return true;
        } else {
            throw result.response;
        }
    } catch (error) {
        const logger = Logger.getInstance();
        logger.logError('[SaveOnboardingFinish]', error); // TODO wysła do sentry
        return false;
    }
}

export { saveOnboardingFinish }
