import * as React from 'react';

type TDropDownKeyDownActions = {
    accept: () => void;
    down: () => void;
    up: () => void;
}

const dropDownKeyDownActions = (
    event: React.KeyboardEvent<HTMLElement>,
    selectionActions: TDropDownKeyDownActions
): void => {
    switch (event.key) {
        case 'Enter':
            event.preventDefault();
            selectionActions.accept();
            break;
        case 'ArrowDown':
            event.preventDefault();
            selectionActions.down();
            break;
        case 'ArrowUp':
            event.preventDefault();
            selectionActions.up();
            break;
    }
};

export { dropDownKeyDownActions, TDropDownKeyDownActions }
